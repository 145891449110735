import styled from '@emotion/styled'
import { InputAdornment, TextField, Typography } from '@material-ui/core'
import React from 'react'
import { ELanguageCode } from '@/schemaTypes'

export interface MultipleLanguageText {
  languageCode: ELanguageCode
  text: string
}
interface MultipleLanguageInputProps {
  type?: string
  values: MultipleLanguageText[]
  onChange?: (e: MultipleLanguageText[]) => void
  className?: string
}

const deFlag = (
  <svg
    width="29"
    height="19"
    viewBox="0 0 29 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1250_714)">
      <path d="M0 6.33334H29V12.6667H0V6.33334Z" fill="white" />
      <path
        d="M0 0H29V6.33333H0V0ZM0 12.6667H29V19H0V12.6667Z"
        fill="#D80027"
      />
    </g>
    <defs>
      <clipPath id="clip0_1250_714">
        <rect width="29" height="19" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

const enFlag = (
  <svg
    width="29"
    height="19"
    viewBox="0 0 29 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1253_717)">
      <path d="M0 0H29V19.0002H0V0Z" fill="white" />
      <path
        d="M16.3125 -0.000167847H12.6875V7.71855H0V11.2811H12.6875V18.9998H16.3125V11.2811H29V7.71855H16.3125V-0.000167847Z"
        fill="#D80027"
      />
      <path
        d="M22.3042 12.8041L28.9999 16.4599V12.8041H22.3042ZM17.6521 12.8041L28.9999 18.9998V17.2478L20.8611 12.8041H17.6521ZM25.9773 18.9998L17.6521 14.454V18.9998H25.9773Z"
        fill="#0052B4"
      />
      <path
        d="M17.6521 12.8041L28.9999 18.9998V17.2478L20.8611 12.8041H17.6521Z"
        fill="white"
      />
      <path
        d="M17.6521 12.8041L28.9999 18.9998V17.2478L20.8611 12.8041H17.6521Z"
        fill="#D80027"
      />
      <path
        d="M5.11697 12.804L0 15.5978V12.804H5.11697ZM11.3478 13.5919V18.9997H1.44383L11.3478 13.5919Z"
        fill="#0052B4"
      />
      <path
        d="M8.13886 12.8041L0 17.2478V18.9998L11.3478 12.8041H8.13886Z"
        fill="#D80027"
      />
      <path
        d="M6.69577 6.19547L0 2.53967V6.19547H6.69577ZM11.3478 6.19547L0 -0.000167847V1.7518L8.13886 6.19547H11.3478ZM3.02268 -0.000167847L11.3478 4.54559V-0.000167847H3.02268Z"
        fill="#0052B4"
      />
      <path
        d="M11.3478 6.19547L0 -0.000167847V1.7518L8.13886 6.19547H11.3478Z"
        fill="white"
      />
      <path
        d="M11.3478 6.19547L0 -0.000167847V1.7518L8.13886 6.19547H11.3478Z"
        fill="#D80027"
      />
      <path
        d="M23.883 6.19558L28.9999 3.4018V6.19558H23.883ZM17.6521 5.40771V-0.000114441H27.5561L17.6521 5.40771Z"
        fill="#0052B4"
      />
      <path
        d="M20.8611 6.19547L28.9999 1.7518V-0.000167847L17.6521 6.19547H20.8611Z"
        fill="#D80027"
      />
    </g>
    <defs>
      <clipPath id="clip0_1253_717">
        <rect width="29" height="19" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

const FLAG_MAP = {
  [ELanguageCode.De]: deFlag,
  [ELanguageCode.En]: enFlag,
}

export const MultipleLanguageInput = (props: MultipleLanguageInputProps) => {
  const { values, type = 'text', onChange, className } = props

  const handleChange = (text: string, index: number) => {
    onChange?.(
      values.map((value, i) => {
        if (i !== index) return value
        return { ...value, text }
      }),
    )
  }

  return (
    <MultipleLanguageInputWapper className={className}>
      {values.map((value, i) => (
        <Input
          key={value.languageCode}
          type={type}
          value={value.text}
          onChange={(e) => handleChange(e.target.value, i)}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {FLAG_MAP[value.languageCode]}
              </InputAdornment>
            ),
          }}
        />
      ))}
    </MultipleLanguageInputWapper>
  )
}

const MultipleLanguageInputWapper = styled.div`
  margin-bottom: 1rem;
`

interface MultipleLanguageInputWithDescriptionProps {
  type?: string
  values: MultipleLanguageText[]
  onChange?: (e: MultipleLanguageText[]) => void
  className?: string
  descriptions: MultipleLanguageText[] | null
  onChangeDescription: (e: MultipleLanguageText[]) => void
}

export const MultipleLanguageInputWithDescription = (
  props: MultipleLanguageInputWithDescriptionProps,
) => {
  const {
    values,
    type = 'text',
    onChange,
    className,
    descriptions,
    onChangeDescription,
  } = props

  const handleChangeLabel = (text: string, index: number) => {
    onChange?.(
      values.map((value, i) => {
        if (i !== index) return value
        return { ...value, text }
      }),
    )
  }

  const handleChangeDescription = (text: string, index: number) => {
    if (!descriptions) return

    onChangeDescription(
      descriptions.map((value, i) => {
        if (i !== index) return value
        return { ...value, text }
      }),
    )
  }

  return (
    <MultipleLanguageInputWithDescriptionWapper className={className}>
      {values.map((value, i) => {
        const description = descriptions?.find(
          (d) => d.languageCode === value.languageCode,
        )
        return (
          <div
            style={{
              borderBottom:
                i !== values.length - 1
                  ? 'solid 1px rgba(224, 224, 224, 1)'
                  : 'none',
            }}
          >
            <Input
              key={value.languageCode}
              type={type}
              value={value.text}
              onChange={(e) => handleChangeLabel(e.target.value, i)}
              fullWidth
              style={{ borderBottom: 'none' }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {FLAG_MAP[value.languageCode]}
                  </InputAdornment>
                ),
                disableUnderline: true,
              }}
            />
            <Input
              key={description?.languageCode}
              type={type}
              style={{ height: 30, marginTop: -24, marginLeft: 10 }}
              value={description?.text}
              onChange={(e) => handleChangeDescription(e.target.value, i)}
              fullWidth
              InputProps={{
                startAdornment: (
                  <Typography style={{ fontSize: 12, width: 80 }}>
                    Description:
                  </Typography>
                ),
                disableUnderline: true,
              }}
            />
          </div>
        )
      })}
    </MultipleLanguageInputWithDescriptionWapper>
  )
}

const MultipleLanguageInputWithDescriptionWapper = styled.div`
  margin-bottom: 1rem;
  border: solid 1px rgba(224, 224, 224, 1);
  border-radius: 5px;
`

const Input = styled(TextField)`
  > div {
    padding: 0.5rem;
  }
`
