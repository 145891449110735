import {
  getCustomDealFormQuestionsUpdateArgs,
  getDefaultQuestions,
} from '../../../../domains/customDealForms/helpers'
import styled from '@emotion/styled'
import { Box, Button, IconButton, Switch, Typography } from '@material-ui/core'
import Add from '@material-ui/icons/Add'
import ArrowBackIos from '@material-ui/icons/ArrowBackIos'
import CheckIcon from '@material-ui/icons/Check'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import MenuIcon from '@material-ui/icons/Menu'
import { uuid4 } from '@sentry/utils'
import { useFormik } from 'formik'
import omit from 'lodash/omit'
import { useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'
import { Container as DragContainer, Draggable } from 'react-smooth-dnd'
import * as Yup from 'yup'
import CategorySelector from '@/components/CategorySelector'
import { getQuestionLabel } from '@/components/ItemFormAnswers'
import Loading from '@/components/Loading'
import { Context } from '@/context'
import { CustomDealFormQuestionCreate } from '@/domains/customDealForms/components/CustomDealFormQuestionCreate'
import {
  useCreateCustomDealForm,
  useGetCustomDealFormById,
  useUpdateCustomDealForm,
} from '@/domains/customDealForms/hooks'
import {
  includeHidedCategories,
  useGetItemCategories,
} from '@/domains/itemCategories/hooks'
import { useMutationShowingErrors } from '@/hooks'
import {
  CustomDealFormQuestionCreateArgs,
  CustomDealFormQuestionUpdateArgs,
  ECustomDealFormQuestionTitleKey,
} from '@/schemaTypes'

interface Props {
  id?: string
}

type QuestionCreateArgsFormData = CustomDealFormQuestionCreateArgs & {
  isNew: boolean
  _id: string
}

interface CreateFormData {
  id?: string
  categoryId: string
  questions: QuestionCreateArgsFormData[] | CustomDealFormQuestionUpdateArgs[]
  isUsedForCommissionSale: boolean
}

const customDealFormSchema = Yup.object().shape({
  categoryId: Yup.string().required(),
})

const isCreateArgs = (
  question: QuestionCreateArgsFormData | CustomDealFormQuestionUpdateArgs,
): question is QuestionCreateArgsFormData => {
  return 'isNew' in question && question.isNew
}

export const CustomDealFormPage = (props: Props) => {
  const [question, setQuestion] = useState<CustomDealFormQuestionUpdateArgs>()

  const { language } = useContext(Context)
  const { t } = useTranslation()
  const history = useHistory()
  const { id } = props
  const [isOpenCreateQuestion, setOpenCreateQuestion] = useState(false)
  const { queryResult } = useGetCustomDealFormById({
    variables: {
      languageCode: language,
      id: id!,
    },
    skip: !id,
  })
  const { queryResult: queryCategoriesResult, itemCategories } =
    useGetItemCategories(includeHidedCategories)

  const createCustomDealForm = useMutationShowingErrors({
    mutation: useCreateCustomDealForm(),
    successMessage: t('custom_deal_form.custom_deal_form_entry_created'),
  })

  const updateCustomDealForm = useMutationShowingErrors({
    mutation: useUpdateCustomDealForm(),
    successMessage: t('custom_deal_form.custom_deal_form_entry_updated'),
  })

  const customDealForm = queryResult.data?.getCustomDealFormById

  const defaultQuestions: QuestionCreateArgsFormData[] = useMemo(
    () =>
      id
        ? []
        : getDefaultQuestions().map((question) => ({
            ...question,
            _id: uuid4(),
            isNew: true,
          })),
    [id],
  )

  const formik = useFormik<CreateFormData>({
    initialValues: {
      id: customDealForm?._id,
      categoryId: customDealForm?.categoryId ?? '',
      isUsedForCommissionSale: customDealForm?.isUsedForCommissionSale ?? false,
      questions: id
        ? getCustomDealFormQuestionsUpdateArgs(customDealForm?.questions ?? [])
        : defaultQuestions,
    },
    enableReinitialize: true,
    validationSchema: customDealFormSchema,
    onSubmit: (values, actions) => {
      const { id, categoryId, questions, isUsedForCommissionSale } = values

      setTimeout(() => {
        actions.setSubmitting(false)
      }, 1000)

      const toSaveQuestions = (
        questions as (
          | QuestionCreateArgsFormData
          | CustomDealFormQuestionUpdateArgs
        )[]
      ).map((question) => {
        if (isCreateArgs(question)) {
          return omit(question, ['isNew', '_id'])
        }
        return question
      })

      if (!id) {
        createCustomDealForm({
          variables: {
            customDealFormCreateArgs: {
              categoryId,
              questions: toSaveQuestions,
              isUsedForCommissionSale,
            },
          },
          onCompleted: (data) => {
            history.replace(
              `/inApp/configurations/CustomDealForms/edit/${data.createCustomDealForm._id}`,
            )
          },
        })
        return
      }

      updateCustomDealForm({
        variables: {
          customDealFormUpdateArgs: {
            _id: id,
            categoryId,
            isUsedForCommissionSale,
            questions: toSaveQuestions as CustomDealFormQuestionUpdateArgs[],
          },
        },
      })
      queryResult.refetch()
    },
  })

  const { values, setFieldValue, errors } = formik

  const hasConditionQuestion = useMemo(
    () =>
      values.questions.some(
        (question) =>
          question.titleKey === ECustomDealFormQuestionTitleKey.Condition,
      ),
    [values.questions],
  )

  const onOpenCreateQuestion = () => {
    setQuestion(undefined)
    setOpenCreateQuestion(true)
  }

  const onCloseCreateQuestionModal = () => {
    setQuestion(undefined)
    setOpenCreateQuestion(false)
  }

  const onEditQuestion = (question: CustomDealFormQuestionUpdateArgs) => {
    setQuestion(question)
    setOpenCreateQuestion(true)
  }

  const onRemoveQuestion = (questionId: string) => {
    setFieldValue(
      'questions',
      values.questions.filter(({ _id }) => _id !== questionId),
    )
  }

  const onDrop = ({ removedIndex, addedIndex }) => {
    const questions = values.questions.filter((_, i) => i !== removedIndex)
    questions.splice(addedIndex, 0, values.questions[removedIndex])
    setFieldValue('questions', questions)
  }

  const onSaveQuestion = (
    updatedQuestion:
      | CustomDealFormQuestionCreateArgs
      | CustomDealFormQuestionUpdateArgs,
  ) => {
    if ('_id' in updatedQuestion && updatedQuestion._id) {
      setFieldValue(
        'questions',
        (
          values.questions as (
            | QuestionCreateArgsFormData
            | CustomDealFormQuestionUpdateArgs
          )[]
        ).map((question) =>
          updatedQuestion._id === question._id
            ? { ...question, ...updatedQuestion }
            : question,
        ),
      )
    } else {
      setFieldValue('questions', [
        ...values.questions,
        { ...updatedQuestion, _id: uuid4(), isNew: true },
      ])
    }
    onCloseCreateQuestionModal()
  }

  if (
    queryResult.loading ||
    queryCategoriesResult.loading ||
    (!values.categoryId && queryResult.data?.getCustomDealFormById.categoryId)
  )
    return (
      <div>
        <Loading />
      </div>
    )

  return (
    <div>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb="2rem"
      >
        <Typography variant="h5">
          <BackIcon>
            <Link to={`/inApp/configurations/CustomDealForms`}>
              <ArrowBackIos />
            </Link>
            {t(id ? 'UPDATE_CUSTOM_DEAL_FORM' : 'CREATE_CUSTOM_DEAL_FORM')}
          </BackIcon>
        </Typography>

        <Button
          color="primary"
          variant="contained"
          startIcon={<CheckIcon />}
          onClick={formik.submitForm}
          disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
        >
          {t(id ? 'UPDATE_FORM' : 'CREATE_FORM')}
        </Button>
      </Box>
      <Box mb="2rem">
        <Typography variant="h6" gutterBottom>
          {t('category')}
        </Typography>
        <CategorySelector
          categories={itemCategories}
          onChange={(categoryIds) => {
            setFieldValue('categoryId', categoryIds)
          }}
          currentCategories={itemCategories.filter(
            (category) => category._id === values.categoryId,
          )}
          hideLabel
        />
        <Typography variant="caption" color="error">
          {errors.categoryId && t(errors.categoryId)}
        </Typography>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb="2rem"
      >
        <Typography variant="h6">{t('questions')}</Typography>

        <Button
          color="primary"
          variant="outlined"
          startIcon={<Add />}
          onClick={() => onOpenCreateQuestion()}
        >
          {t('add_question')}
        </Button>
      </Box>
      {!!values.questions.length && (
        <QuestionList>
          <DragContainer
            dragHandleSelector=".drag-handle"
            lockAxis="y"
            onDrop={onDrop}
          >
            {(
              values.questions as (
                | QuestionCreateArgsFormData
                | CustomDealFormQuestionUpdateArgs
              )[]
            ).map(
              (
                question:
                  | QuestionCreateArgsFormData
                  | CustomDealFormQuestionUpdateArgs,
              ) => (
                <Draggable key={question._id}>
                  <Question>
                    <div className="drag-handle">
                      <IconButton style={{ padding: '0.5rem' }}>
                        <MenuIcon />
                      </IconButton>
                    </div>
                    <QuestionLabel>
                      {getQuestionLabel(question.label, language)}
                    </QuestionLabel>
                    <QuestionType>{question.type}</QuestionType>
                    <div>
                      <IconButton
                        color="primary"
                        style={{ padding: '0.5rem' }}
                        onClick={() => onEditQuestion(question)}
                      >
                        <EditIcon />
                      </IconButton>
                    </div>
                    <IconButton
                      style={{ padding: '0.5rem' }}
                      onClick={() => onRemoveQuestion(question._id)}
                      color="secondary"
                      disabled={
                        !!question.titleKey &&
                        [
                          ECustomDealFormQuestionTitleKey.Price,
                          ECustomDealFormQuestionTitleKey.ProductName,
                          ECustomDealFormQuestionTitleKey.AdditionalInfo,
                        ].includes(question.titleKey)
                      }
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Question>
                </Draggable>
              ),
            )}
          </DragContainer>
        </QuestionList>
      )}

      <CommisionSaleContainer>
        <Switch
          checked={values.isUsedForCommissionSale}
          onChange={(e) =>
            setFieldValue('isUsedForCommissionSale', e.target.checked)
          }
        />
        {t('custom_deal_form.is_used_for_commission_sale')}
      </CommisionSaleContainer>

      {isOpenCreateQuestion && (
        <CustomDealFormQuestionCreate
          question={question}
          onClose={onCloseCreateQuestionModal}
          onSave={onSaveQuestion}
          hasConditionQuestion={hasConditionQuestion}
        />
      )}
    </div>
  )
}

const QuestionList = styled.div`
  margin-top: 1rem;
  border: 1px solid #21212114;
`

const Question = styled.div`
  display: flex;
  align-items: center;
  padding: 0.25rem;
`

const QuestionLabel = styled.div`
  flex: 2;
  margin: 0 1rem;
`

const QuestionType = styled.div`
  flex: 1;
  margin: 0 1rem;
`

const BackIcon = styled.span`
  a {
    display: inline-block;
    color: #212121;
    height: 24px;
    width: 24px;
    vertical-align: middle;
  }
`

const CommisionSaleContainer = styled.div`
  display: flex;
  align-items: center;
  padding-block: 1rem;
`
