import { QueryHookOptions, useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { useShowErrorsPopup } from '@/hooks'
import { Query, QueryGetProductPropertyDefinitionArgs } from '@/schemaTypes'

const GET_PRODUCT_PROPERTY_DEFINITION = gql`
  query getProductPropertyDefinition($productPropertyDefinitionId: ObjectId!) {
    getProductPropertyDefinition(
      productPropertyDefinitionId: $productPropertyDefinitionId
    ) {
      _id
      key
      type
      categoryIds
      impactsLook
      sortPriority
      description {
        allTranslations {
          text
          languageCode
        }
      }
      name {
        allTranslations {
          text
          languageCode
        }
      }
      idealoName
      info {
        allTranslations {
          text
          languageCode
        }
      }
      valueFormatting {
        suffix
      }
      isInUse
    }
  }
`

type TData = {
  getProductPropertyDefinition: Query['getProductPropertyDefinition']
}
type TVariables = QueryGetProductPropertyDefinitionArgs

export function useGetProductPropertyDefinition(
  opts: QueryHookOptions<TData, TVariables> = {},
) {
  const queryResult = useQuery<TData, TVariables>(
    GET_PRODUCT_PROPERTY_DEFINITION,
    {
      fetchPolicy: 'network-only',
      ...opts,
    },
  )

  useShowErrorsPopup(queryResult.error)

  return {
    queryResult,
    productPropertyDefinition: queryResult.data?.getProductPropertyDefinition,
  }
}
