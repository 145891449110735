import isEmpty from 'lodash/isEmpty'
import { useCallback, useState } from 'react'
import React from 'react'
import { Switch } from 'react-router-dom'
import EmployeeShopPopup from '@/components/EmployeeShopPopup'
import SentryRoute from '@/components/SentryRoute'
import Menubar from '@/redesign/domains/layout/components/Menubar/Menubar'
import SidebarMenu from '@/redesign/domains/layout/components/SidebarMenu/SidebarMenu'
import CustomerBlacklistRoutes from '@/redesign/pages/InApp/CustomerBlacklist/customerBlacklist.routes'
import CustomersRoutes from '@/redesign/pages/InApp/Customers/customers.routes'
import DealsList from '@/redesign/pages/InApp/Deals'
import DealDetailPage from '@/redesign/pages/InApp/Deals/[dealId]'
import { CrawlerConfigPage } from '@/redesign/pages/InApp/ProductConfigurator/ProductConfigurator'
import { useAppSelector } from '@/store'
import styles from '@/styles/app.module.scss'
import AuctionsRoutes from './Auctions/auctions.routes'
import cashBookRoutes from './CashBook/cashBook.routes'
import CustomDealFormsRoutes from './Configurations/CustomDealForms/customDealForms.routes'
import ConfigurationsRoutes from './Configurations/configurations.routes'
import CsvExport from './CsvExport'
import CustomDealsRoutes from './CustomDeals/customDeals.routes'
import EmployeeChangePassword from './EmployeeChangePassword'
import ImportExportsRoutes from './ImportExport/importExport.routes'
import ItemsRoutes from './Items/items.routes'
import PrivateSalesRoutes from './PrivateSales/privateSales.routes'
import ProductsRoutes from './Products/products.routes'
import ProductPropertyDefinitionsRoutes from './product-property-definitions/productPropertyDefinitions.routes'

type Props = {
  onLogout: () => void
}

export const InApp = React.memo((props: Props) => {
  const currentUser = useAppSelector((state) => state.user.current)
  const _employeeActiveShop = useAppSelector(
    (state) => state.ui.employeeActiveShop,
  )

  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false)
  const [isShowedEmployeeActiveShopPopup, setIsShowedEmployeeActiveShopPopup] =
    useState<boolean>(false)
  const [
    isShowedEmployeeChangeShopPopupFromMenu,
    setIsShowedEmployeeChangeShopPopupFromMenu,
  ] = useState<boolean>(false)

  const isShowedEmployeeChangeShop =
    (!isShowedEmployeeActiveShopPopup &&
      currentUser?.company?._id &&
      isEmpty(_employeeActiveShop)) ||
    isShowedEmployeeChangeShopPopupFromMenu

  const _closeShowEmployeeChangeShop = () => {
    setIsShowedEmployeeActiveShopPopup(true)
    setIsShowedEmployeeChangeShopPopupFromMenu(false)
  }

  const toggleDrawerOpen = useCallback(() => {
    setIsDrawerOpen((prevState) => !prevState)
  }, [])

  const onShowedEmployeeChangeShopPopup = useCallback(() => {
    setIsShowedEmployeeActiveShopPopup(true)
  }, [])

  const closeDrawer = useCallback(() => {
    setIsDrawerOpen(false)
  }, [])

  return (
    <div className="grid grid-rows-[min-content,auto] p-3 min-h-screen">
      <Menubar
        currentUser={currentUser}
        onLogout={props.onLogout}
        onSideMenuOpen={toggleDrawerOpen}
        onShowedEmployeeChangeShopPopup={onShowedEmployeeChangeShopPopup}
      />

      <div>
        <div>
          <SidebarMenu
            visible={isDrawerOpen}
            onClose={closeDrawer}
            currentUser={currentUser}
          />
        </div>
        <div className={`${styles.stretch} h-full`}>
          <Switch>
            <SentryRoute
              path="/inApp/change-password"
              component={EmployeeChangePassword}
            />
            <SentryRoute
              path="/inApp/deals/:dealId"
              component={DealDetailPage}
            />
            <SentryRoute
              path="/inApp/customDeals"
              component={CustomDealsRoutes}
            />
            <SentryRoute
              path="/inApp/customDealForms"
              component={CustomDealFormsRoutes}
            />
            <SentryRoute path="/inApp/customers" component={CustomersRoutes} />
            <SentryRoute path="/inApp/cashBook" component={cashBookRoutes} />
            <SentryRoute
              path="/inApp/importExport"
              component={ImportExportsRoutes}
            />
            <SentryRoute path="/inApp/exportEntries" component={CsvExport} />
            <SentryRoute
              path="/inApp/configurations"
              component={ConfigurationsRoutes}
            />
            {/* <SentryRoute path="/inApp/profile/" render={props => <ProfilePage {...props} employeeId={this.props.currentUser?._id} />} /> */}
            {/* <SentryRoute path="/inApp/userAccount" render={props => <UserAccount {...props} employeeId={this.props.currentUser?._id} />} /> */}
            <SentryRoute path="/inApp/items" component={ItemsRoutes} />
            <SentryRoute path="/inApp/auctions" component={AuctionsRoutes} />
            <SentryRoute
              path="/inApp/privatesales"
              component={PrivateSalesRoutes}
            />
            {process.env.REACT_APP_ENV !== 'production' && (
              <SentryRoute
                path={'/inApp/product-property-definitions'}
                component={ProductPropertyDefinitionsRoutes}
              />
            )}
            {process.env.REACT_APP_ENV !== 'production' && (
              <SentryRoute path="/inApp/products" component={ProductsRoutes} />
            )}
            {process.env.REACT_APP_ENV !== 'production' && (
              <SentryRoute
                path="/inApp/productconfigurator"
                component={CrawlerConfigPage}
              />
            )}
            <SentryRoute
              path="/inApp/customerBlacklists"
              component={CustomerBlacklistRoutes}
            />
            <SentryRoute path="/" component={DealsList} />
          </Switch>
        </div>
      </div>
      {isShowedEmployeeChangeShop && (
        <EmployeeShopPopup onClose={_closeShowEmployeeChangeShop} />
      )}
    </div>
  )
})
