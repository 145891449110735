import { useGetPropertyDefinitionsSortPriorities } from '../hooks/getPropertyDefinitionsSortPriorities'
import { Button, Typography } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import { useFormik } from 'formik'
import { uniq } from 'lodash'
import { Checkbox } from 'primereact/checkbox'
import { Tooltip } from 'primereact/tooltip'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import CategorySelector from '@/components/CategorySelector'
import {
  includeHidedCategories,
  useGetItemCategories,
} from '@/domains/itemCategories/hooks'
import { EProductProperyDefinitionSuffix } from '@/domains/productPropertyDefinitions/components/enums'
import { useCreateProductPropertyDefinition } from '@/domains/productPropertyDefinitions/hooks/createProductPropertyDefinition'
import { useGetProductPropertyDefinition } from '@/domains/productPropertyDefinitions/hooks/getProductPropertyDefinition'
import { useUpdateProductPropertyDefinition } from '@/domains/productPropertyDefinitions/hooks/updateProductPropertyDefinition'
import { useMutationShowingErrors } from '@/hooks'
import BreadCrumbBar from '@/redesign/components/BreadCrumbBar/BreadCrumbBar'
import { Label } from '@/redesign/components/FieldWrapper/FieldWrapper'
import Input from '@/redesign/components/Input/Input'
import InputDropdown from '@/redesign/components/InputDropdown/InputDropdown'
import InputNum from '@/redesign/components/InputNumber/InputNumber'
import Textarea from '@/redesign/components/Textarea/Textarea'
import {
  ELanguageCode,
  EOrderBy,
  EProductPropertyType,
  MutationCreateProductPropertyDefinitionArgs,
  MutationUpdateProductPropertyDefinitionArgs,
} from '@/schemaTypes'

interface Props {
  id?: string
}

type MutationCreateOrUpdateProductPropertyDefinitionArgs =
  | MutationCreateProductPropertyDefinitionArgs
  | MutationUpdateProductPropertyDefinitionArgs

const typeMapping: Record<
  number,
  {
    type: EProductPropertyType
    suffix?: EProductProperyDefinitionSuffix
    orderBy?: EOrderBy
  }
> = {
  0: { type: EProductPropertyType.Translation },
  1: {
    type: EProductPropertyType.Number,
    suffix: EProductProperyDefinitionSuffix.Gb,
  },
  2: {
    type: EProductPropertyType.Number,
    suffix: EProductProperyDefinitionSuffix.Cm,
  },
  3: {
    type: EProductPropertyType.Number,
    suffix: EProductProperyDefinitionSuffix.Inch,
  },
  4: {
    type: EProductPropertyType.Number,
    suffix: EProductProperyDefinitionSuffix.G,
  },
  5: {
    type: EProductPropertyType.Number,
    suffix: EProductProperyDefinitionSuffix.Ounce,
    orderBy: EOrderBy.Desc,
  },
  6: { type: EProductPropertyType.Boolean },
  7: { type: EProductPropertyType.Number },
}

export function ProductPropertyDefinitionDetails(props: Props) {
  const { t } = useTranslation()
  const [initialCategorieIds, setInitialCategorieIds] = useState([])

  const history = useHistory()
  const { id } = props

  const { itemCategories } = useGetItemCategories(includeHidedCategories)

  const { productPropertyDefinition, queryResult } =
    useGetProductPropertyDefinition({
      variables: {
        productPropertyDefinitionId: id,
      },
      skip: !id,
    })

  const { propertyDefinitionsSortPriorities } =
    useGetPropertyDefinitionsSortPriorities()

  const createProductPropertyDefinition = useMutationShowingErrors({
    mutation: useCreateProductPropertyDefinition(),
    hideSuccessMessage: true,
  })

  const updateProductPropertyDefinition = useMutationShowingErrors({
    mutation: useUpdateProductPropertyDefinition(),
    successMessage: t('product_property_definition.save_success'),
  })

  const typeOptions = useMemo(() => {
    return [
      { label: `${t('translated_text')}`, value: 0 },
      {
        label: `${t('product_property_definition.storage')} (${EProductProperyDefinitionSuffix.Gb})`,
        value: 1,
      },
      {
        label: `${t('product_property_definition.size')} (${EProductProperyDefinitionSuffix.Cm})`,
        value: 2,
      },
      {
        label: `${t('product_property_definition.size')} (${EProductProperyDefinitionSuffix.Inch})`,
        value: 3,
      },
      {
        label: `${t('weight')} (${EProductProperyDefinitionSuffix.G})`,
        value: 4,
      },
      {
        label: `${t('weight')} (${EProductProperyDefinitionSuffix.Ounce})`,
        value: 5,
      },
      { label: `${t('boolean')}`, value: 6 },
      { label: `${t('number')}`, value: 7 },
    ]
  }, [t])

  const initialDefinitionType = useMemo(() => {
    if (!productPropertyDefinition) return null

    const type = productPropertyDefinition.type
    const suffix = productPropertyDefinition.valueFormatting?.suffix

    if (type === EProductPropertyType.Translation) return 0
    if (
      type === EProductPropertyType.Number &&
      suffix === EProductProperyDefinitionSuffix.Gb
    )
      return 1
    if (
      type === EProductPropertyType.Number &&
      suffix === EProductProperyDefinitionSuffix.Cm
    )
      return 2
    if (
      type === EProductPropertyType.Number &&
      suffix === EProductProperyDefinitionSuffix.Inch
    )
      return 3
    if (
      type === EProductPropertyType.Number &&
      suffix === EProductProperyDefinitionSuffix.G
    )
      return 4
    if (
      type === EProductPropertyType.Number &&
      suffix === EProductProperyDefinitionSuffix.Ounce
    )
      return 5
    if (type === EProductPropertyType.Boolean) return 6

    if (type === EProductPropertyType.Number) return 7

    return null
  }, [productPropertyDefinition])

  const productPropertyDefinitionValidationSchema = useMemo(
    () =>
      buildFormDataValidationSchema(
        propertyDefinitionsSortPriorities,
        productPropertyDefinition?.sortPriority,
      ),
    [
      propertyDefinitionsSortPriorities,
      productPropertyDefinition?.sortPriority,
    ],
  )

  const [prevCategoryIds, setPrevCategoryIds] = useState(
    productPropertyDefinition?.categoryIds,
  )
  if (productPropertyDefinition?.categoryIds !== prevCategoryIds) {
    setPrevCategoryIds(productPropertyDefinition?.categoryIds)
    setInitialCategorieIds(productPropertyDefinition?.categoryIds)
  }

  const formik = useFormik<ProductPropertyDefinitionFormData>({
    initialValues: {
      key: productPropertyDefinition?.key,
      nameEn: productPropertyDefinition?.name.allTranslations.find(
        (a) => a.languageCode === ELanguageCode.En,
      )?.text,
      nameDe: productPropertyDefinition?.name.allTranslations.find(
        (a) => a.languageCode === ELanguageCode.De,
      )?.text,
      idealoName: productPropertyDefinition?.idealoName,
      typeIndex: initialDefinitionType ?? 0,
      impactsLook: productPropertyDefinition?.impactsLook,
      categoryIds: productPropertyDefinition?.categoryIds,
      sortPriority: productPropertyDefinition?.sortPriority,
      descriptionEn:
        productPropertyDefinition?.description?.allTranslations.find(
          (a) => a.languageCode === ELanguageCode.En,
        )?.text ?? '',
      descriptionDe:
        productPropertyDefinition?.description?.allTranslations.find(
          (a) => a.languageCode === ELanguageCode.De,
        )?.text ?? '',
    },
    enableReinitialize: true,
    validationSchema: productPropertyDefinitionValidationSchema,
    onSubmit: (values, actions) => {
      const {
        key,
        nameDe,
        nameEn,
        categoryIds,
        typeIndex,
        impactsLook,
        idealoName,
        sortPriority,
        descriptionEn,
        descriptionDe,
      } = values

      if (!key || !nameEn || !nameDe || typeof typeIndex === 'undefined') {
        return
      }

      const valueFormatting: {
        suffix?: string
        orderBy?: EOrderBy
      } = {}
      if (typeMapping[typeIndex].suffix)
        valueFormatting.suffix = typeMapping[typeIndex].suffix
      if (typeMapping[typeIndex].orderBy)
        valueFormatting.orderBy = typeMapping[typeIndex].orderBy

      const variables: MutationCreateOrUpdateProductPropertyDefinitionArgs = {
        args: {
          key,
          name: [
            {
              languageCode: ELanguageCode.En,
              text: nameEn,
            },
            {
              languageCode: ELanguageCode.De,
              text: nameDe,
            },
          ],
          idealoName,
          impactsLook: impactsLook ?? false,
          categoryIds: uniq(categoryIds),
          type: typeMapping[typeIndex].type,
          valueFormatting,
          sortPriority,
          description: [
            {
              languageCode: ELanguageCode.En,
              text: descriptionEn,
            },
            {
              languageCode: ELanguageCode.De,
              text: descriptionDe,
            },
          ],
        },
      }

      if (id) {
        updateProductPropertyDefinition({
          variables: {
            args: {
              ...variables.args,
              _id: id,
            },
          },
          onCompleted: () => {
            actions.setSubmitting(false)
            queryResult.refetch()
          },
        })
        return
      }
      createProductPropertyDefinition({
        variables,
        onCompleted: () => {
          actions.setSubmitting(false)
          history.push(`/inApp/product-property-definitions`)
        },
      })
    },
  })

  const { values, setFieldValue, errors } = formik

  const currentDefaultCategories = itemCategories.filter((category) =>
    values.categoryIds?.includes(category._id),
  )

  return (
    <div>
      <Tooltip target=".custom-target-icon" />
      <BreadCrumbBar />

      <div className="flex flex-col md:flex-row mb-4 mt-4">
        <Typography variant="h5" gutterBottom>
          {t('product_property_definition.title_details', {
            propertyName: values.key,
          })}
        </Typography>
      </div>

      <Input
        className="flex flex-col md:flex-row items-start md:items-center mb-4"
        labelClassName="text-sm font-semibold w-12.5"
        inputContainerClassName="w-52"
        value={values.key}
        onChange={(e) => setFieldValue('key', e.target.value)}
        label={t('product_property_definition.property_name')}
        isInvalid={!!errors.key}
        errorText={t(`${errors.key}`)}
      />

      <Input
        className="flex flex-col md:flex-row items-start md:items-center mb-4"
        labelClassName="text-sm font-semibold w-12.5"
        inputContainerClassName="w-52"
        value={values.idealoName}
        onChange={(e) => setFieldValue('idealoName', e.target.value.trim())}
        label={t('product_property_definition.idealo_name')}
        isInvalid={!!errors.idealoName}
        errorText={t(`${errors.idealoName}`)}
      />

      <div className="flex flex-col md:flex-row">
        <Label className="text-sm font-semibold w-12.5 mt-1">
          {t('product_property_definition.display_name')}
        </Label>

        <Input
          className="mb-4"
          labelClassName=" ml-1 text-sm font-semibold"
          inputContainerClassName="w-52"
          value={values.nameEn}
          onChange={(e) => setFieldValue('nameEn', e.target.value)}
          label={t('english')}
          isInvalid={!!errors.nameEn}
          errorText={t(`${errors.nameEn}`)}
        />

        <Input
          className="mb-4 ml-10"
          labelClassName=" ml-1 text-sm font-semibold"
          inputContainerClassName="w-52"
          value={values.nameDe}
          onChange={(e) => setFieldValue('nameDe', e.target.value)}
          label={t('german')}
          isInvalid={!!errors.nameDe}
          errorText={t(`${errors.nameDe}`)}
        />
      </div>

      <div className="flex flex-col md:flex-row">
        <Label className="text-sm font-semibold w-12.5 mt-1">
          {t('product_property_definition.description')}
        </Label>

        <Textarea
          className="mb-4"
          labelClassName=" ml-1 text-sm font-semibold"
          inputContainerClassName="w-52"
          value={values.descriptionEn}
          onChange={(e) => setFieldValue('descriptionEn', e.target.value)}
          label={t('english')}
          isInvalid={!!errors.descriptionEn}
          errorText={t(`${errors.descriptionEn}`)}
          tooltipText={t('product_property_definition.description_tooltip')}
          tooltipIcon={'pi pi-question-circle'}
        />

        <Textarea
          className="mb-4 ml-10"
          labelClassName=" ml-1 text-sm font-semibold"
          inputContainerClassName="w-52"
          value={values.descriptionDe}
          onChange={(e) => setFieldValue('descriptionDe', e.target.value)}
          label={t('german')}
          isInvalid={!!errors.descriptionDe}
          errorText={t(`${errors.descriptionDe}`)}
          tooltipText={t('product_property_definition.description_tooltip')}
          tooltipIcon={'pi pi-question-circle'}
        />
      </div>

      <InputNum
        mode="decimal"
        maxFractionDigits={0}
        value={values.sortPriority}
        label={t('product_property_definition.sort_priority')}
        className="flex flex-col md:flex-row items-start md:items-center mb-4"
        labelClassName="text-sm font-semibold w-12.5"
        inputContainerClassName="w-52"
        onChange={(e) => setFieldValue('sortPriority', e.value)}
        tooltipText={t('product_property_definition.sort_priority_tooltip')}
        tooltipIcon={'pi pi-question-circle'}
        isInvalid={!!errors.sortPriority}
        errorText={t(`${errors.sortPriority}`)}
      />

      <InputDropdown
        label={t('type')}
        options={typeOptions}
        value={values.typeIndex}
        onChange={(e) => setFieldValue('typeIndex', e.target.value)}
        className="flex flex-col md:flex-row items-start md:items-center mb-6"
        labelClassName="text-sm font-semibold w-12.5"
        inputContainerClassName="w-52"
        isInvalid={!!errors.typeIndex}
        errorText={t(`${errors.typeIndex}`)}
        disabled={productPropertyDefinition?.isInUse}
      />

      <div className="flex flex-col md:flex-row mb-4">
        <Label className="text-sm font-semibold w-12.5 mt-1">
          {t('categories')}
        </Label>

        <Typography
          variant="h6"
          gutterBottom
          className={`u-flex u-ai-center`}
        />
        <CategorySelector
          multiple
          hideLabel
          categories={itemCategories}
          onChange={(itemCategoryIds) =>
            setFieldValue('categoryIds', itemCategoryIds)
          }
          currentCategories={currentDefaultCategories}
          disableClear={productPropertyDefinition?.isInUse}
          initialCategorieIds={initialCategorieIds}
        />
        {typeof errors.categoryIds === 'string' && (
          <Typography variant="caption" color="error">
            {t(errors.categoryIds)}
          </Typography>
        )}
      </div>

      <div className="flex flex-col md:flex-row mb-4">
        <Label className="text-sm font-semibold w-12.5">
          {t('product_property_definition.impacts_look')}
        </Label>

        <Checkbox
          onChange={(e) => setFieldValue('impactsLook', e.checked)}
          checked={values.impactsLook ?? false}
        />
      </div>

      <div>
        <Button
          className="!mt-4"
          color="primary"
          variant="contained"
          startIcon={<CheckIcon />}
          onClick={formik.submitForm}
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {t('save')}
        </Button>
      </div>
    </div>
  )
}

type ProductPropertyDefinitionFormData = Yup.InferType<
  ReturnType<typeof buildFormDataValidationSchema>
>

const buildFormDataValidationSchema = (
  propertyDefinitionsSortPriorities: number[],
  existingPropertySortPriority: number,
) => {
  return Yup.object().shape({
    key: Yup.string().required('error.error_validation_is_required'),
    idealoName: Yup.string().nullable(),
    typeIndex: Yup.number().required('error.error_validation_is_required'),
    nameEn: Yup.string().required('error.error_validation_is_required'),
    nameDe: Yup.string().required('error.error_validation_is_required'),
    categoryIds: Yup.array(),
    impactsLook: Yup.bool(),
    sortPriority: Yup.number()
      .required('error.error_validation_is_required')
      .test('', function (value) {
        return !propertyDefinitionsSortPriorities
          .filter((c) => c !== existingPropertySortPriority)
          .includes(Number(value))
      })
      .label('sort priority'),
    descriptionEn: Yup.string().nullable(),
    descriptionDe: Yup.string().nullable(),
  })
}
