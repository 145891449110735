import styled from '@emotion/styled'
import { Typography } from '@material-ui/core'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { EProductPropertyType, ProductPropertyDefinition } from '@/schemaTypes'
import { EProductProperyDefinitionSuffix } from './enums'

interface ProductProperyDefinitionPreviewOptions {
  productPropertyDefinition: ProductPropertyDefinition
}

export function ProductProperyDefinitionPreview({
  productPropertyDefinition: {
    _id,
    key,
    name,
    type,
    valueFormatting,
    categories,
    sortPriority,
  },
}: ProductProperyDefinitionPreviewOptions) {
  const { t } = useTranslation()
  const history = useHistory()

  const definitionType = useMemo(() => {
    if (type === EProductPropertyType.Translation) {
      return t('translated_text')
    }

    const suffix = valueFormatting?.suffix as
      | EProductProperyDefinitionSuffix
      | null
      | undefined

    if (type === EProductPropertyType.Number) {
      if (suffix === EProductProperyDefinitionSuffix.Gb) {
        return t('product_property_definition.storage')
      }

      if (
        suffix &&
        [
          EProductProperyDefinitionSuffix.Cm,
          EProductProperyDefinitionSuffix.Inch,
        ].includes(suffix)
      ) {
        return t('product_property_definition.size')
      }

      if (
        suffix &&
        [
          EProductProperyDefinitionSuffix.G,
          EProductProperyDefinitionSuffix.Ounce,
        ].includes(suffix)
      ) {
        return t('weight')
      }
    }

    if (type === EProductPropertyType.Boolean) {
      return t('boolean')
    }

    if (type === EProductPropertyType.Number) {
      return t('number')
    }

    return null
  }, [t, valueFormatting?.suffix, type])

  return (
    <PreviewContainer
      onClick={() =>
        history.push(`/inApp/product-property-definitions/edit/${_id}`)
      }
    >
      <Typography style={{ fontSize: 12, height: 20 }}>
        {definitionType}
      </Typography>
      <Typography style={{ fontSize: 24 }}>{key}</Typography>
      <Typography style={{ fontSize: 14, height: 30 }}>
        {name.allTranslations.map((a) => (
          <div>
            {a.languageCode.toUpperCase()} : {a.text}
          </div>
        ))}
      </Typography>
      <Typography style={{ fontSize: 14, marginTop: 12 }}>
        <div>
          {t('order')}: {sortPriority}
        </div>
      </Typography>
      <Typography style={{ marginTop: 25, fontSize: 14 }}>
        {t('added_to')}:
      </Typography>
      <div className="flex justify-start mt-2">
        {categories.length ? (
          categories.map((category, index) => {
            if (index > 2) return null

            return (
              <span
                style={{
                  height: 22,
                  fontSize: 12,
                  lineHeight: 1,
                  background: '#BEA9FB',
                  borderRadius: 5,
                  marginRight: 5,
                  padding: 5,
                }}
              >
                {index < 2
                  ? category.name
                  : categories.length === 3
                    ? category.name
                    : `+${categories.length - 2} More...`}
              </span>
            )
          })
        ) : (
          <span>-</span>
        )}
      </div>
    </PreviewContainer>
  )
}

const PreviewContainer = styled.div`
  background: #efefef;
  border-radius: 20px;
  padding: 20px;
  cursor: pointer;
`
