import { useState } from 'react'
import ProductSelector from '@/components/ProductSelector'
import { CustomDealItem } from '@/domains/customDeals/helpers'
import { EItemEvaluationActionType } from '@/domains/deals/components/constants/enums'
import ItemEvaluation from '@/domains/items/components/Item/ItemEvaluation/ItemEvaluation'
import CustomItemDraft, {
  CustomItemDraftFormData,
} from '@/redesign/domains/customDeals/components/CustomDealItems/CustomItemDraft/CustomItemDraft'
import { EDealType, ItemAnswer, ProductVariant, Scalars } from '@/schemaTypes'

export interface ItemDraftQuestionsFormData {
  answers: ItemAnswer[]
  algoliaReference?: string
  variantId?: string
  itemCategoryId: string
  title: string
  material?: string
  ean?: string
  payoutAmountPawn?: number
  payoutAmountPurchase?: number
}

interface Props {
  durationInDays: number
  minimumPawnDuration: number
  onSaveItem: (formData: ItemDraftQuestionsFormData) => void
  companyId: Scalars['ObjectId']

  dealType: EDealType
  items: CustomDealItem[]
  onChangeDealType: (dealType: EDealType) => void
  onSaveCustomItem: (formData: CustomItemDraftFormData) => void
}

export function CustomDealItemSelect(props: Props) {
  const {
    items,
    onChangeDealType,
    onSaveCustomItem,
    dealType,
    companyId,
    durationInDays,
    minimumPawnDuration,
    onSaveItem,
  } = props

  const [product, setProduct] = useState<
    | null
    | {
        productVariant: ProductVariant
        itemCategoryId: string
      }
    | { productObjectID: string }
  >(null)

  if (product) {
    if ('productVariant' in product && !product.productVariant.isPriceValid) {
      return (
        <CustomItemDraft
          companyId={companyId}
          durationInDays={durationInDays}
          items={items}
          dealType={dealType}
          onChangeDealType={onChangeDealType}
          onSave={onSaveCustomItem}
          editedIndex={items.length}
          item={product}
        />
      )
    } else if (
      !('productVariant' in product) ||
      product.productVariant.isPriceValid
    ) {
      return (
        <ItemEvaluation
          actionType={EItemEvaluationActionType.ADD_PRODUCT_TO_CUSTOME_DEAL}
          {...product}
          onSaveItemDraft={onSaveItem}
          companyId={companyId}
          durationInDays={durationInDays}
          minimumPawnDuration={minimumPawnDuration}
          productVariantId={
            'productVariant' in product ? product.productVariant.id : undefined
          }
        />
      )
    }
  }

  return (
    <ProductSelector
      allowProductsWithoutValidPrice={true}
      onSelectItem={setProduct}
    />
  )
}
