import Input from '../Input/Input'
import InputDropdown from '../InputDropdown/InputDropdown'
import { find } from 'lodash'
import { useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Context } from '@/context'
import {
  ECustomDealFormQuestionType,
  ELanguageCode,
  FormOptionNumberAnswer,
  FormOptionTextAnswer,
  TranslatedString,
} from '@/schemaTypes'
import { NewFormAnswer } from './ItemFormAnswers'

type ItemFormSelectAnswerProps = {
  disabled?: boolean
  onChange?: (newFormAnswer: NewFormAnswer, allowEnterAnswer?: boolean) => void
  label: string
}

type ItemFormSelectTextAnswerProps = ItemFormSelectAnswerProps & {
  formAnswer: FormOptionTextAnswer
}

export const ItemFormSelectTextAnswer = ({
  formAnswer,
  disabled,
  onChange,
  label,
}: ItemFormSelectTextAnswerProps) => {
  const { language } = useContext(Context)
  const { t } = useTranslation()

  const { optionTextAnswer } = formAnswer
  if (optionTextAnswer && typeof optionTextAnswer !== 'string') {
    throw new Error('Expected optionTextAnswer to be a string')
  }

  const isOption =
    optionTextAnswer &&
    formAnswer.question.options.some((option) =>
      option.label.allTranslations
        .map((tran) => tran.text)
        .includes(optionTextAnswer),
    )

  const [showOtherOption, setShowOtherOption] = useState(!isOption)

  const options = useMemo(() => {
    if (
      formAnswer.question.__typename !== 'TextOptionsCustomDealFormQuestion'
    ) {
      return []
    }

    const array =
      formAnswer.question.options?.map((option, i) => ({
        label: option.label.text ?? getQuestionLabel(option.label, language),
        value: i,
      })) || []

    if (formAnswer.question.allowEnterAnswer) {
      array.push({ label: t('other'), value: -1 })
    }

    return array
  }, [
    formAnswer.question.__typename,
    formAnswer.question.allowEnterAnswer,
    formAnswer.question.options,
    language,
    t,
  ])

  return showOtherOption ? (
    <Input
      label={label}
      className="flex flex-col md:flex-row items-start md:items-center mb-2"
      labelClassName="text-sm font-semibold w-12.5"
      inputContainerClassName="w-52"
      value={optionTextAnswer}
      onChange={(e) => {
        onChange?.(
          {
            type: ECustomDealFormQuestionType.TextOptions,
            value: e.target.value,
            question: formAnswer.question,
          },
          true,
        )
      }}
    />
  ) : (
    <InputDropdown
      label={label}
      value={formAnswer.question.options.findIndex((option) =>
        option.label.allTranslations.some(
          (translation) => translation.text === formAnswer.optionTextAnswer,
        ),
      )}
      options={options}
      className="flex flex-col md:flex-row items-start md:items-center mb-2"
      labelClassName="text-sm font-semibold w-12.5"
      inputContainerClassName="w-52"
      disabled={disabled}
      onChange={(e) => {
        if (e.target.value === -1) {
          setShowOtherOption(true)
          return
        }
        onChange?.({
          type: ECustomDealFormQuestionType.TextOptions,
          value:
            formAnswer.question.options[e.target.value as number]?.label.text,
          question: formAnswer.question,
        })
      }}
    />
  )
}

type ItemFormSelectNumberAnswerProps = ItemFormSelectAnswerProps & {
  formAnswer: FormOptionNumberAnswer
}

export const ItemFormSelectNumberAnswer = ({
  formAnswer,
  disabled,
  onChange,
  label,
}: ItemFormSelectNumberAnswerProps) => {
  const { language } = useContext(Context)
  const { t } = useTranslation()

  const { optionNumberAnswer } = formAnswer
  if (typeof optionNumberAnswer !== 'number') {
    throw new Error('Expected optionNumberAnswer to be a number')
  }

  const isOption = formAnswer.question.options.some((option) =>
    option.label.allTranslations
      .map((tran) => parseFloat(tran.text))
      .includes(optionNumberAnswer),
  )

  const [showOtherOption, setShowOtherOption] = useState(!isOption)

  const options = useMemo(() => {
    if (
      formAnswer.question.__typename !== 'NumberOptionsCustomDealFormQuestion'
    ) {
      return []
    }

    const array =
      formAnswer.question.options?.map((option) => ({
        label: option.label.text ?? getQuestionLabel(option.label, language),
        value: parseFloat(option.label.text),
      })) || []

    if (formAnswer.question.allowEnterAnswer) {
      array.push({ label: t('other'), value: -1 })
    }

    return array
  }, [
    formAnswer.question.__typename,
    formAnswer.question.allowEnterAnswer,
    formAnswer.question.options,
    language,
    t,
  ])

  return showOtherOption ? (
    <Input
      label={label}
      type="number"
      className="flex flex-col md:flex-row items-start md:items-center mb-2"
      labelClassName="text-sm font-semibold w-12.5"
      inputContainerClassName="w-52"
      value={`${optionNumberAnswer}`}
      onChange={(e) => {
        onChange?.(
          {
            type: ECustomDealFormQuestionType.NumberOptions,
            customValue: +e.target.value,
            question: formAnswer.question,
          },
          true,
        )
      }}
    />
  ) : (
    <InputDropdown
      label={label}
      value={formAnswer.optionNumberAnswer}
      options={options}
      className="flex flex-col md:flex-row items-start md:items-center mb-2"
      labelClassName="text-sm font-semibold w-12.5"
      inputContainerClassName="w-52"
      disabled={disabled}
      onChange={(e) => {
        if (e.target.value === -1) {
          setShowOtherOption(true)
          return
        }
        onChange?.({
          type: ECustomDealFormQuestionType.NumberOptions,
          value: e.target.value as number,
          question: formAnswer.question,
        })
      }}
    />
  )
}

const getQuestionLabel = (
  label: TranslatedString,
  language: ELanguageCode.De | ELanguageCode.En,
) => {
  if (Array.isArray(label)) {
    return find(label, { languageCode: language })?.text
  }

  return ''
}
