import algoliasearch from 'algoliasearch/lite'
import classNames from 'classnames'
import get from 'lodash/get'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { InstantSearch, SearchBox, connectHits } from 'react-instantsearch-dom'
import { useSearchProducts } from '@/domains/products/hooks/searchProducts'
import Input from '@/redesign/components/Input/Input'
import Switch from '@/redesign/components/Switch/Switch'
import { ProductVariant, QuerySearchProductsArgs } from '@/schemaTypes'
import ProductVariantSelector from './ProductVariantSelector'
import ProductsTable from './ProductsTable'

export interface ProductSelectorProps {
  allowProductsWithoutValidPrice: boolean
  onSelectItem: (
    item:
      | { productObjectID: string }
      | { productVariant: ProductVariant; itemCategoryId: string },
  ) => void
  className?: string
}

export function ProductSelector(props: ProductSelectorProps) {
  const { t } = useTranslation()
  const [useNewProductDB, setUseNewProductDB] = useState(false)
  const { onSelectItem } = props

  return (
    <div className={classNames('min-h-mc flex flex-col', props.className)}>
      {allowNewProductDb && (
        <div className="flex justify-end">
          <Switch
            checked={useNewProductDB}
            onChange={() => setUseNewProductDB((v) => !v)}
            className="mr-2"
          />
          {t('new_product_db')}
        </div>
      )}

      {!useNewProductDB ? (
        <InstantSearch searchClient={searchClient} indexName="products">
          <SearchBox className="mb-4" />
          <HitComponent
            onSelectProduct={(productObjectID: string) =>
              onSelectItem({ productObjectID })
            }
          />
        </InstantSearch>
      ) : (
        <NewProductDatabaseSearch
          allowProductsWithoutValidPrice={props.allowProductsWithoutValidPrice}
          onSelectProductVariant={(
            productVariant: ProductVariant,
            itemCategoryId: string,
          ) => onSelectItem({ productVariant, itemCategoryId })}
        />
      )}
    </div>
  )
}

export default ProductSelector

const allowNewProductDb = process.env.REACT_APP_ENV !== 'production'

interface NewProductDatabaseSearchProps {
  allowProductsWithoutValidPrice: boolean
  onSelectProductVariant: (
    productVariant: ProductVariant,
    itemCategoryId: string,
  ) => void
}

function NewProductDatabaseSearch({
  onSelectProductVariant,
  allowProductsWithoutValidPrice,
}: NewProductDatabaseSearchProps) {
  const { t } = useTranslation()
  const [searchTerm, setSearchTerm] = useState('')
  const [productId, setProductId] = useState<string | null>(null)

  return (
    <div className="mt-6 flex-grow flex flex-col">
      {!productId && (
        <>
          <Input
            className="mb-4"
            placeholder={t('search')}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <NewProductsTable
            searchTerm={searchTerm}
            onSelectProduct={setProductId}
          />
        </>
      )}

      {productId && (
        <ProductVariantSelector
          allowProductsWithoutValidPrice={allowProductsWithoutValidPrice}
          productId={productId}
          onClickBack={() => setProductId(null)}
          onSelectProductVariant={onSelectProductVariant}
        />
      )}
    </div>
  )
}

interface NewProductsTableProps {
  searchTerm: string
  onSelectProduct: (productId: string) => void
}

function NewProductsTable({
  searchTerm,
  onSelectProduct,
}: NewProductsTableProps) {
  const [searchTime, setSearchTime] = useState(new Date())
  const [prevSearchTerm, setPrevSearchTerm] = useState(searchTerm)
  if (searchTerm !== prevSearchTerm) {
    setSearchTime(new Date())
    setPrevSearchTerm(searchTerm)
  }

  const variables = useMemo(
    (): QuerySearchProductsArgs => ({
      args: {
        search: searchTerm,
        paginationArgs: {
          limit: 50,
        },
        createdBefore: searchTime,
      },
    }),
    [searchTerm, searchTime],
  )

  const { queryResult } = useSearchProducts({
    variables,
  })

  return (
    <ProductsTable
      products={
        queryResult.data?.searchProducts.nodes.map((node) => ({
          id: node._id,
          name: node.name,
          categoryName: node.category.name,
        })) ?? []
      }
      loading={queryResult.loading}
      onSelectProduct={onSelectProduct}
    />
  )
}

interface HitComponentProps {
  hits: any
  onSelectProduct: (id: string) => void
}

const HitComponent = connectHits((props: HitComponentProps) => {
  const { hits, onSelectProduct } = props

  return (
    <ProductsTable
      onSelectProduct={onSelectProduct}
      products={hits.map((h) => ({
        id: h.objectID,
        name: h.title,
        categoryName: get(h, 'categories.lvl0'),
      }))}
    />
  )
})

const searchClient = algoliasearch(
  'X6I9UWVU91',
  '4f4ab531d852f217ffd70c5e16314d87',
)
