import {
  includeHidedCategories,
  useGetItemCategories,
} from '../../../itemCategories/hooks'
import StorageUnitsConfiguration from '../../../storageUnits/components'
import { useGetParentCategories } from '../../hooks'
import {
  useGetActiveEmployees,
  useGetActiveStorageFacilities,
  useGetStorageUnits,
  useUpdateItem,
} from '../../hooks'
import { useGetAuctionByItemId } from '../../hooks/itemDetails/getAuctionByItemId'
import { Box, Checkbox, TextField } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import EditIcon from '@material-ui/icons/Edit'
import { KeyboardDatePicker } from '@material-ui/pickers'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { isEqual } from 'lodash'
import findLast from 'lodash/findLast'
import {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import { ReactSVG } from 'react-svg'
import ExpansionBox from '@/components/ExpansionBox/ExpansionBox'
import Info from '@/components/Info'
import { ItemFormAnswers, NewFormAnswer } from '@/components/ItemFormAnswers'
import ItemStatus, { EHintType } from '@/components/ItemStatus'
import ItemValuesTable from '@/components/ItemValuesTable/ItemValuesTable'
import FeatureQuestionAnswer from '@/components/Layout/FeatureQuestionAnswer/FeatureQuestionAnswer'
import NoteContainer from '@/components/Note/NoteContainer'
import NotePopup, { INoteBuild } from '@/components/Note/NotePopup'
import { useFormatVariantProperties } from '@/components/ProductSelector/helper'
import SelectOptionInput from '@/components/SelectOptionInput'
import StorageHistory from '@/components/StorageHistory'
import TextInput from '@/components/TextInput'
import { Context } from '@/context'
import { useMutationShowingErrors, useValidFromToDateChange } from '@/hooks'
import HelpLink from '@/images/help-link.svg'
import ResetIcon from '@/images/reset.svg'
import SaveIcon from '@/images/save.svg'
import {
  AddItemToStorageUnitArgs,
  Deal,
  EAuctionItemStatus,
  ECustomDealFormQuestionType,
  EItemStatusType,
  ETransportMode,
  EUsedGrade,
  Item,
  ItemCategory,
  ItemEvent,
  ItemQuestion,
  NoteCreateArgs,
  NoteUpdateArgs,
  ShippingLabelCreateArgs,
} from '@/schemaTypes'
import {
  getLatestEventForStatus,
  getRegisteredCustomer,
  isDealClosed,
} from '@/utils/deal'
import {
  isElectronic,
  isShownImei,
  isUsedCondition,
  isVehicle,
  useNestedState,
} from '@/utils/misc'
import { defaultTimezone } from '@/utils/time'
import { calcDirectPurchaseFactor } from '@/utils/valorization'
import AmountRow from './AmountRow'
import { AuctionItem } from './AuctionItem'
import CarIdBlock from './CarIdBlock'
import CheckBoxRow from './CheckBoxRow'
import DateRow from './DateRow'
import styles from './Item.module.scss'
import { EditableItemTitle, ItemTitle } from './ItemTitle'
import ItemUploadsOverview from './ItemUploadsOverview'
import ItemValorizationDetails from './ItemValorizationDetails'
import ProductIdentificationTable from './ProductIdentificationTable/ProductIdentificationTable'
import ValorizationHistory from './ValorizationHistory'

dayjs.extend(utc)

export interface ItemBlockProps {
  deal: Deal
  item: Item
  itemQuestions: ItemQuestion[]
  directPurchaseFactor?: number
  purchasePriceFactor?: number
  onSaveTitle: (title: string) => Promise<unknown>
  onChangeIsInvestmentGold: (isInvestmentGold: boolean) => void
  onChangeCheckedAfterAuction: (checkedAfterAuction: boolean) => void
  onSaveSerialNumber: (serialNumber: string | null) => void
  onSaveImei: (imei: string | null) => void
  onSaveItemSpecification: (itemSpecification: string | null) => void
  onChangeEntrupyCertificate: (entrupyCertificate: string | null) => void
  onChangeCheckedBy: (checkedBy: string | null) => void
  onChangeCheckedAt: (checkedAt: Date | null) => void
  onChangeStorageFacilityId: (storageFacilityId: string) => void
  onSaveUsedGrade: (usedGrade: EUsedGrade | null | undefined) => void

  onChangeItemStatus: (
    newEvent: ItemEvent,
    hint: EHintType,
    shippingLabelCreateArgs?: ShippingLabelCreateArgs | undefined,
    relocateItem?: boolean,
    relocateNote?: string,
    addItemToStorageUnitArgs?: AddItemToStorageUnitArgs,
  ) => void
  onRemoveLatestEvent: () => void
  updateStatusLoading: boolean
  itemCategory: ItemCategory
  usedGradeQuestion?: ItemQuestion | null
  onAddItemNote: (note: NoteCreateArgs) => void
  onUpdateItemNote: (noteId: string, note: NoteUpdateArgs) => void
}

interface expansionBoxFilterInput {
  expandedIdentification?: boolean
  expandedProduct?: boolean
  expandedUploads?: boolean
  expandedItemStatus?: boolean
  expandedValuation?: boolean
  expandedValorization?: boolean
  expandedValorizationHistory?: boolean
  expandedStorage?: boolean
  expandedNotes?: boolean
  expandedDamageCaseAndPersonalUse?: boolean
}

const coinsAndBarsCategoryIds = [
  '612e510d880278dd21d87dde',
  '612e510d880278dd21d87df6',
  '612e510f880278dd21d87e41',
  '612e510f880278dd21d87e46',
  '612e5110880278dd21d87e5d',
  '612e510d880278dd21d87df5',
  '612e510f880278dd21d87e43',
  '612e5110880278dd21d87e5a',
]

export function ItemBlock(props: ItemBlockProps) {
  const {
    deal,
    item,
    itemQuestions,
    onSaveTitle,
    onChangeIsInvestmentGold,
    onChangeCheckedAfterAuction,
    onSaveSerialNumber,
    onSaveImei,
    onSaveItemSpecification,
    onChangeEntrupyCertificate,
    onChangeCheckedBy,
    onChangeCheckedAt,
    onChangeItemStatus,
    onRemoveLatestEvent,
    onChangeStorageFacilityId,
    updateStatusLoading,
    itemCategory,
    onSaveUsedGrade,
    onAddItemNote,
    onUpdateItemNote,
  } = props

  const { isGuestUser } = useContext(Context)
  const { t } = useTranslation()
  const { language } = useContext(Context)
  const [serialNumber, setSerialNumber] = useState(item.serialNumber ?? '')
  const [imei, setImei] = useState(item.imei ?? '')
  const [itemSpecification, setItemSpecification] = useState(
    item.itemSpecification ?? 'N/A',
  )
  const [isItemSpecificationDisabled, setIsItemSpecificationDisabled] =
    useState(!item.itemSpecification)
  const [entrupyCertificateUrl, setEntrupyCertificateUrl] = useState(
    item.entrupyCertificateUrl ?? '',
  )
  const [checkedBy, setCheckedBy] = useState(item.checkedBy ?? '')
  const [checkedAt, setCheckedAt] = useState(item.checkedAt)
  const [isInvestmentGold, setIsInvestmentGold] = useState(
    item.isInvestmentGold,
  )
  const [checkedAfterAuction, setCheckedAfterAuction] = useState(
    item.checkedAfterAuction,
  )
  const [showRequiredField, setShowRequiredField] = useState(false)
  const [formAnswers, setFormAnswers] = useState(item.formAnswers ?? [])
  const [openNotePopup, setOpenNotePopup] = useState(false)
  const [noteBuilds, setNoteBuilds] = useState<INoteBuild[]>([])
  const onDateChange = useValidFromToDateChange(setCheckedAt)
  const [usedGrade, setUsedGrade] = useState<EUsedGrade | null | undefined>(
    item.usedGrade,
  )

  const [licensePlate, setLicensePlate] = useState<{
    value?: string | null
    error?: string
  }>({ value: item.vehicleData?.licensePlate })
  const [spareTireCount, setSpareTireCount] = useState<{
    value?: number | null
    error?: string
  }>({ value: item.vehicleData?.spareTireCount })

  const { search } = useLocation()

  const isBookedDeal =
    deal.events[deal.events.length - 1].__typename === 'DealBookedEvent'
  const onSellEvent = getLatestEventForStatus(deal, 'DealOnSellEvent')
  const usedGradeMenuItems = Object.values(EUsedGrade).map((c) => {
    return {
      label: t(c),
      value: c,
    }
  })

  const lifeInsuranceCategoryName = 'Lebensversicherung'

  const lastDealHistory = item.dealsHistory[item.dealsHistory.length - 1]
  const isItemNameEditable =
    (item.customRequestId || item.customDealId) &&
    !lastDealHistory.algoliaReference &&
    !lastDealHistory.variantId

  const showUsedGradeMissing = () => {
    const dealItem = deal.items.find((c) => isEqual(c._id, item._id))
    if (!dealItem) return true

    const answeredQuestions = (dealItem?.answers ?? []).map((answer) => ({
      answer,
      question: (dealItem.questions ?? []).find(
        (question) => question._id === answer.questionId,
      ),
    }))
    return (
      isElectronic(dealItem.itemCategory) &&
      isUsedCondition(answeredQuestions) &&
      !item.usedGrade
    )
  }

  const updateItem = useMutationShowingErrors({
    mutation: useUpdateItem(),
    successMessage: `${t('item_updated_successfully')}!`,
  })

  const handleItemSpecificationNotNeeded = () => {
    if (!item.itemSpecification) {
      setItemSpecification('')
      setIsItemSpecificationDisabled(!isItemSpecificationDisabled)
    } else {
      setItemSpecification('')
      setIsItemSpecificationDisabled(!isItemSpecificationDisabled)
      onSaveItemSpecification(null)
    }
  }

  useEffect(() => {
    setImei((state) => item.imei ?? state)
    setSerialNumber((state) => item.serialNumber ?? state)
  }, [item.imei, item.serialNumber])

  const { itemCategories } = useGetItemCategories(includeHidedCategories)

  const { parentCategories } = useGetParentCategories({
    variables: {
      itemCategoryId:
        item.dealsHistory[item.dealsHistory.length - 1].itemCategoryId,
    },
    skip: !item.dealsHistory[item.dealsHistory.length - 1].itemCategoryId,
  })

  const currentCategories = itemCategories.filter(
    (category) =>
      category._id ===
      item.dealsHistory[item.dealsHistory.length - 1].itemCategoryId,
  )
  const helpLink = useMemo(() => {
    if (currentCategories[0]?.helpLink) return currentCategories[0].helpLink
    const matchedItem = findLast(
      parentCategories || [],
      (item) => item.helpLink,
    ) as ItemCategory | undefined
    return matchedItem?.helpLink
  }, [currentCategories, parentCategories])

  const dealItem = deal.items.find((dealItem) => dealItem._id === item._id)

  const formattedVariantProperties = useFormatVariantProperties(
    dealItem.productVariant,
  )

  const { auction } = useGetAuctionByItemId({
    variables: {
      itemId: dealItem?._id,
      languageCode: language,
    },
    skip: !dealItem?._id,
  })

  const itemValuesColumns = {
    verified: { displayed: true, showLoadingAnimationForNullValues: false },
    closed: { displayed: true, showLoadingAnimationForNullValues: false },
  }
  const itemValues = item.dealsHistory[item.dealsHistory.length - 1].values
  const itemValuesRows = itemValues.verified
    ? [
        {
          label: 'Maximaler Marktwert',
          values: {
            verified: itemValues.verified.maxMarketValue,
            closed: itemValues.closed && itemValues.closed.maxMarketValue,
          },
        },
        {
          label: 'Jetziger Marktwert',
          values: {
            verified: itemValues.verified.currentMarketValue,
            closed: itemValues.closed && itemValues.closed.currentMarketValue,
          },
        },
        {
          label: 'Zukünftiger Marktwert',
          values: {
            verified: itemValues.verified.predictedMarketValue,
            closed: itemValues.closed && itemValues.closed.predictedMarketValue,
          },
        },
        {
          label: 'Adjustierter Marktwert',
          values: {
            verified: itemValues.verified.adjustedMarketValue,
            closed: itemValues.closed && itemValues.closed.adjustedMarketValue,
          },
        },
        {
          label: 'Loan To Value',
          values: {
            verified: itemValues.verified.loanToValue,
            closed: itemValues.closed && itemValues.closed.loanToValue,
          },
        },
        {
          label: 'Payout Amount',
          values: {
            verified: itemValues.verified.payoutAmount ?? 'NA',
            closed:
              itemValues.closed && (itemValues.closed.payoutAmount ?? 'NA'),
          },
        },
      ]
    : []

  const isCar = dealItem?.itemCategory && isVehicle(dealItem.itemCategoryId)

  const productIdentificationRows = [
    { label: t('id'), value: item._id },
    { label: t('storage.label'), value: item.storageLabel },
    {
      label: t('product_reference'),
      value:
        dealItem?.algoliaReference ??
        dealItem?.variantId ??
        (dealItem?.vehicleData ? t('vehicle') : t('custom')),
    },
    !isCar && {
      label: t('serial_number'),
      value: serialNumber,
      onChange: (serialNumber: string) => {
        setSerialNumber(serialNumber)
      },
      onSave: () => {
        onSaveSerialNumber(serialNumber ?? null)
      },
      onReset: () => {
        setSerialNumber(item.serialNumber ?? '')
      },
      hint: !serialNumber ? (
        <Button
          style={{ marginLeft: '15px' }}
          onClick={() => setSerialNumber('N/A')}
        >
          {t('not_available')}
        </Button>
      ) : undefined,
    },
    isShownImei(itemCategory) && {
      label: t('imei'),
      value: imei,
      onChange: (imei: string) => {
        setImei(imei)
      },
      onSave: () => {
        onSaveImei(imei ?? null)
      },
      onReset: () => {
        setImei(item.imei ?? '')
      },
      hint: !imei ? (
        <Button style={{ marginLeft: '15px' }} onClick={() => setImei('N/A')}>
          {t('not_available')}
        </Button>
      ) : undefined,
    },
  ].filter(Boolean)

  const answeredQuestions = (dealItem?.answers ?? []).map((answer) => ({
    answer,
    question: (itemQuestions ?? []).find(
      (question) => question._id === answer.questionId,
    ),
  }))
  const lastItemStatus = item.events
    ? item.events[item.events.length - 1].event
    : null
  const canEditItemAnswers =
    (lastItemStatus !== EItemStatusType.NotRelevant && !isCar) || isBookedDeal
  const canChangeItem = !getLatestEventForStatus(
    deal,
    'DealExtensionConfirmedEvent',
  )
  const simLockQuestionId = dealItem.questions.find(
    (question) => question.titleKey === 'QUESTION_OPEN_FOR_ALL_CARRIERS',
  )?._id
  const isLockedSmartPhone =
    simLockQuestionId &&
    dealItem.itemCategory.algoliaCategorySlug.includes('Smartphones') &&
    dealItem?.answers?.some(
      (answer) =>
        answer.questionId === simLockQuestionId &&
        answer.selectedOptionIndex === 1,
    )

  const itemId = item._id

  const { storageFacilities } = useGetActiveStorageFacilities()
  const { employees } = useGetActiveEmployees()
  const { storageUnits } = useGetStorageUnits()

  const storageFacility = storageFacilities.find(
    (sf) => sf._id === item.storageFacilityId,
  )

  const vehicleData = item.vehicleData

  const [odometer] = useState(vehicleData?.vehicleProperties?.odometer)
  const fetchedVehicleProperties = vehicleData
    ? [
        ...Object.keys(vehicleData.vehicleProperties)
          .filter(
            (k) =>
              k !== 'otherVehicleProperties' &&
              k !== '__typename' &&
              k !== 'odometer' &&
              vehicleData.vehicleProperties[k] != undefined,
          )
          .map((k) => ({ name: k, value: vehicleData.vehicleProperties[k] })),
        ...(vehicleData.vehicleProperties.otherVehicleProperties ?? []),
      ]
    : []
  const expansionBoxFilter = useNestedState<expansionBoxFilterInput>(
    {},
    {
      persistent: 'expansionBoxFilter',
    },
  )

  const handleExpansionBoxes = (value, name) => {
    if (name === 'expandedIdentification') {
      expansionBoxFilter.setProperty('expandedIdentification', value)
    } else if (name === 'expandedProduct') {
      expansionBoxFilter.setProperty('expandedProduct', value)
    } else if (name === 'expandedUploads') {
      expansionBoxFilter.setProperty('expandedUploads', value)
    } else if (name === 'expandedItemStatus') {
      expansionBoxFilter.setProperty('expandedItemStatus', value)
    } else if (name === 'expandedValuation') {
      expansionBoxFilter.setProperty('expandedValuation', value)
    } else if (name === 'expandedValorization') {
      expansionBoxFilter.setProperty('expandedValorization', value)
    } else if (name === 'expandedValorizationHistory') {
      expansionBoxFilter.setProperty('expandedValorizationHistory', value)
    } else if (name === 'expandedStorage') {
      expansionBoxFilter.setProperty('expandedStorage', value)
    } else if (name === 'expandedNotes') {
      expansionBoxFilter.setProperty('expandedNotes', value)
    } else if (name === 'expandedDamageCaseAndPersonalUse') {
      expansionBoxFilter.setProperty('expandedDamageCaseAndPersonalUse', value)
    }

    expansionBoxFilter.persist()
  }

  const onChangeAnswer = ({
    index,
    newFormAnswer,
  }: {
    index: number
    newFormAnswer: NewFormAnswer
  }) => {
    setFormAnswers((state) =>
      state.map((item, i) => {
        if (i === index) {
          if (
            item.__typename === 'FormTextAnswer' &&
            newFormAnswer.type === ECustomDealFormQuestionType.TextDefault
          ) {
            return { ...item, textAnswer: newFormAnswer.value }
          }

          if (
            item.__typename === 'FormMultilineTextAnswer' &&
            newFormAnswer.type === ECustomDealFormQuestionType.TextMultiline
          ) {
            return { ...item, textMultilineAnswer: newFormAnswer.value }
          }

          if (
            item.__typename === 'FormNumberAnswer' &&
            newFormAnswer.type === ECustomDealFormQuestionType.NumberDefault
          ) {
            return { ...item, numberAnswer: newFormAnswer.value }
          }

          if (
            item.__typename === 'FormBooleanAnswer' &&
            newFormAnswer.type === ECustomDealFormQuestionType.Boolean
          ) {
            return { ...item, booleanAnswer: newFormAnswer.value }
          }

          if (
            item.__typename === 'FormOptionNumberAnswer' &&
            newFormAnswer.type === ECustomDealFormQuestionType.NumberOptions
          ) {
            return { ...item, optionNumberAnswer: newFormAnswer.value }
          }

          if (
            item.__typename === 'FormOptionTextAnswer' &&
            newFormAnswer.type === ECustomDealFormQuestionType.TextOptions
          ) {
            return { ...item, optionTextAnswer: newFormAnswer.value }
          }
        }

        return item
      }),
    )
  }

  const auctionItemSoldToPerson = useMemo(() => {
    if (!auction) return false

    const auctionedItem = auction.items?.find(
      (item) => item.itemId === dealItem?._id,
    )

    if (auctionedItem?.status !== EAuctionItemStatus.Sold) {
      return false
    }

    return !!auctionedItem?.buyer
  }, [auction, dealItem])

  useEffect(() => {
    const notePopupBuilds: INoteBuild[] = []

    if (item && item.notes?.length) {
      const itemPopupNotes = item.notes.filter((note) => note.isPopup)
      if (itemPopupNotes.length) {
        notePopupBuilds.push({
          title: 'On Item',
          notes: itemPopupNotes,
        })
      }
    }

    const registeredCustomer = getRegisteredCustomer(deal)
    if (registeredCustomer?.note && registeredCustomer?.isNotePopup) {
      notePopupBuilds.push({
        title: 'On Customer',
        notes: [
          {
            content: registeredCustomer.note,
          },
        ],
        hideUserInfo: true,
      })
    }

    if (notePopupBuilds.length) {
      setNoteBuilds(notePopupBuilds)
      setOpenNotePopup(true)
    }
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const query = new URLSearchParams(search)
    const showRequiredField = query.get('show_required_field')
    if (!showRequiredField) return
    setShowRequiredField(true)
  }, [search])

  const onChangeLicensePlate = useCallback(
    (value: string) => {
      if (!value) {
        setLicensePlate((data) => ({
          ...data,
          error: t('error.error_validation_is_required'),
        }))
      } else {
        setLicensePlate((data) => ({ ...data, error: undefined }))
      }
      setLicensePlate((data) => ({ ...data, value }))
    },
    [t],
  )

  const onChangeSpareTireCount = useCallback(
    (value: string) => {
      setSpareTireCount((data) => ({
        ...data,
        value: value ? parseInt(value) : undefined,
      }))

      if (!value) {
        setSpareTireCount((data) => ({
          ...data,
          error: t('error.error_validation_is_required'),
        }))
      } else if (parseInt(value) < 0) {
        setSpareTireCount((data) => ({
          ...data,
          error: t('spare_tire_count_is_less_than_zero'),
        }))
      } else {
        setSpareTireCount((data) => ({ ...data, error: undefined }))
      }
    },
    [t],
  )

  return (
    <Paper className="u-p-sm u-12/12 u-mb-sm">
      <div className="u-flex u-jc-sb">
        {dealItem && (
          <>
            {isItemNameEditable ? (
              <EditableItemTitle
                itemTitle={dealItem.productVariant?.name ?? dealItem.title}
                onSaveTitle={onSaveTitle}
              />
            ) : (
              <ItemTitle
                itemTitle={dealItem.productVariant?.name || dealItem.title}
              />
            )}
          </>
        )}
        {!isGuestUser() && (
          <div>
            {canChangeItem && isBookedDeal && (
              <Link
                to={`/inApp/items/${itemId}/${isCar ? 'editVehicleProperties' : 'addItem'}`}
              >
                <Button
                  className="u-mr-mini"
                  variant="contained"
                  classes={{ root: styles.button }}
                >
                  <EditIcon classes={{ root: styles.editIcon }} />{' '}
                  {t('change_item')}
                </Button>
              </Link>
            )}
            {canEditItemAnswers && (
              <Link to={`/inApp/items/${itemId}/editAnswers`}>
                <Button
                  className="u-mr-mini"
                  variant="contained"
                  classes={{ root: styles.button }}
                >
                  <EditIcon classes={{ root: styles.editIcon }} />{' '}
                  {t('edit_answers')}
                </Button>
              </Link>
            )}
          </div>
        )}
      </div>
      <ExpansionBox
        title={t('product_identification')}
        name={'expandedIdentification'}
        openAtLoad={expansionBoxFilter.values.expandedIdentification ?? false}
        onClickBox={(value, name) => handleExpansionBoxes(value, name)}
      >
        <Paper className="u-p-sm u-12/12 u-mb-sm">
          <ProductIdentificationTable rows={productIdentificationRows} />
        </Paper>
      </ExpansionBox>

      <ExpansionBox
        title={t('product_information')}
        name={'expandedProduct'}
        openAtLoad={expansionBoxFilter.values.expandedProduct ?? false}
        onClickBox={(value, name) => handleExpansionBoxes(value, name)}
      >
        <Paper className="u-p-sm u-12/12 u-mb-sm">
          <Typography
            component="div"
            variant="body1"
            gutterBottom
            className="u-pt-sm"
          >
            <Box display="flex">
              <Box display="flex">
                <div className="u-mr-mini">{t('category')}:</div>
                {currentCategories[0]?.name}
              </Box>
              {helpLink && (
                <a href={helpLink} target="_blank" rel="noreferrer">
                  <ReactSVG
                    className={`${styles.helpLinkIcon} u-pl-15`}
                    src={HelpLink}
                  />
                </a>
              )}
            </Box>
            <br />
          </Typography>

          <Box margin="1rem 0" display="flex" alignItems="center">
            <Typography>{t('item_specification')}</Typography>

            <TextInput
              value={itemSpecification ?? 'N/A'}
              disabled={isItemSpecificationDisabled}
              onChange={(e) => setItemSpecification(e)}
              inputWidth={'300px'}
            />

            <Box display="flex" alignItems="center">
              <Button
                variant="contained"
                onClick={handleItemSpecificationNotNeeded}
                className="u-mt-sm u-mr-sm u-ml-sm u-mb-sm"
                classes={{ root: styles.noteButton }}
              >
                {t(
                  isItemSpecificationDisabled
                    ? 'any_description_want_to_add'
                    : 'not_needed',
                )}
              </Button>
              <Box
                onClick={() => {
                  setItemSpecification('')
                  setIsItemSpecificationDisabled(false)
                }}
              >
                <ReactSVG
                  className={`${styles.infoIcon} u-pb-15`}
                  src={ResetIcon}
                />
              </Box>
              <Box
                onClick={() => {
                  !isItemSpecificationDisabled &&
                    onSaveItemSpecification(itemSpecification)
                }}
              >
                <ReactSVG
                  className={`${styles.infoIcon} u-pl-15 u-pb-15`}
                  src={SaveIcon}
                />
              </Box>
            </Box>
          </Box>

          {dealItem.productVariant && (
            <>
              <Typography className="!mb-2.5 !font-semibold">
                {t('variant_details')}:
              </Typography>
              <table className={`${styles.table} mb-4`}>
                <tbody>
                  {formattedVariantProperties.map((property) => {
                    return (
                      <tr>
                        <td className="w-2/5">{property.title}:</td>
                        <td>{property.value}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </>
          )}

          {currentCategories[0]?.name !== lifeInsuranceCategoryName && (
            <Typography className="!mb-2.5 !font-semibold">
              {t('item_questions')}:
            </Typography>
          )}
          <table className={`${styles.table}`}>
            <tbody>
              {currentCategories[0]?.name === lifeInsuranceCategoryName ? (
                <tr className={`${styles.tr}`}>
                  <td>
                    {t('police_number')}:{' '}
                    <span style={{ marginLeft: '14rem' }}>
                      {item.policeNumber}
                    </span>
                  </td>
                </tr>
              ) : (
                answeredQuestions.map((entry, index) => {
                  return (
                    <FeatureQuestionAnswer
                      answer={entry.answer}
                      question={entry.question}
                      key={index}
                    />
                  )
                })
              )}
            </tbody>
          </table>
          {vehicleData && (
            <table className={`${styles.table}`}>
              {fetchedVehicleProperties.map((carProperty) => {
                return (
                  <tr id={carProperty.name} className={`${styles.tr}`}>
                    <td className={`${styles.td}`}>
                      {t('car_' + carProperty.name)}{' '}
                    </td>
                    <td>{carProperty.value}</td>
                  </tr>
                )
              })}

              <AmountRow
                label={t('car_odometer')}
                defaultValue={odometer}
                onChange={(value) =>
                  updateItem({
                    variables: {
                      args: {
                        _id: itemId,
                        vehicleData: { vehicleProperties: { odometer: value } },
                      },
                    },
                  })
                }
              />
            </table>
          )}

          {!!formAnswers.length && (
            <Box display="flex" alignItems="flex-end">
              <Box width="50%" mt="1rem">
                <ItemFormAnswers
                  formAnswers={formAnswers}
                  onChangeAnswer={onChangeAnswer}
                />
              </Box>
              <div
                onClick={() =>
                  updateItem({
                    variables: {
                      args: {
                        _id: itemId,
                        formAnswers: formAnswers.map(
                          ({ question, ...formAnswer }) => {
                            if (formAnswer.__typename === 'FormTextAnswer') {
                              return {
                                ...formAnswer,
                                textAnswer: formAnswer.textAnswer,
                              }
                            }

                            if (
                              formAnswer.__typename ===
                              'FormMultilineTextAnswer'
                            ) {
                              return {
                                ...formAnswer,
                                textAnswer: formAnswer.textMultilineAnswer,
                              }
                            }

                            if (formAnswer.__typename === 'FormNumberAnswer') {
                              return {
                                ...formAnswer,
                                numberAnswer: formAnswer.numberAnswer,
                              }
                            }

                            if (
                              formAnswer.__typename === 'FormOptionNumberAnswer'
                            ) {
                              return {
                                ...formAnswer,
                                optionNumberAnswer:
                                  formAnswer.optionNumberAnswer,
                              }
                            }

                            if (
                              formAnswer.__typename === 'FormOptionTextAnswer'
                            ) {
                              return {
                                ...formAnswer,
                                optionTextAnswer: formAnswer.optionTextAnswer,
                              }
                            }

                            if (formAnswer.__typename === 'FormBooleanAnswer') {
                              return {
                                ...formAnswer,
                                booleanAnswer: formAnswer.booleanAnswer,
                              }
                            }

                            if (
                              formAnswer.__typename === 'FormFileUploadAnswer'
                            ) {
                              return {}
                            }

                            throw new Error('Invaldid formAnswer type')
                          },
                        ),
                      },
                    },
                  })
                }
                className={`o-media__fixed u-inline-block ${styles.saveIcon} u-mb-15`}
              >
                <ReactSVG
                  className={`${styles.infoIcon} u-pl-15 u-pb-15`}
                  src={SaveIcon}
                />
              </div>
            </Box>
          )}

          {vehicleData && (
            <CarIdBlock
              vehicleData={vehicleData}
              item={item}
              deal={deal}
              showRequiredField={showRequiredField}
            />
          )}

          {/* Put here rest of the code  */}

          <table className={`${styles.table}`}>
            <tbody>
              {vehicleData && (
                <>
                  <Box mt="1rem" />
                  <CheckBoxRow
                    label={t('sale_contract')}
                    value={!!vehicleData.hasSaleContract}
                    onChange={(v) => {
                      updateItem({
                        variables: {
                          args: {
                            _id: itemId,
                            vehicleData: { hasSaleContract: v },
                          },
                        },
                      })
                    }}
                    hasAsterisk={showRequiredField}
                  />
                  <CheckBoxRow
                    label={t('type_certificate')}
                    value={!!vehicleData?.hasTypeCertificate}
                    onChange={(v) => {
                      updateItem({
                        variables: {
                          args: {
                            _id: itemId,
                            vehicleData: { hasTypeCertificate: v },
                          },
                        },
                      })
                    }}
                    hasAsterisk={showRequiredField}
                  />
                  <CheckBoxRow
                    label={t('approval_certificate')}
                    value={!!vehicleData.hasApprovalCertificate}
                    onChange={(v) => {
                      updateItem({
                        variables: {
                          args: {
                            _id: itemId,
                            vehicleData: { hasApprovalCertificate: v },
                          },
                        },
                      })
                    }}
                    hasAsterisk={showRequiredField}
                  />
                  <DateRow
                    label={t('has57agutachtendue')}
                    value={vehicleData.has57aGutachtenDue}
                    onChange={(v) => {
                      updateItem({
                        variables: {
                          args: {
                            _id: itemId,
                            vehicleData: { has57aGutachtenDue: v },
                          },
                        },
                      })
                    }}
                    hasAsterisk={showRequiredField}
                  />

                  <tr style={{ width: '100%' }}>
                    <td style={{ width: '40%' }}>
                      <p>
                        {t('57agutachten')}
                        {showRequiredField && (
                          <span className={styles.alert}>*</span>
                        )}
                        :
                      </p>
                    </td>
                    <td style={{ display: 'flex', alignItems: 'center' }}>
                      <SelectOptionInput
                        hasNone
                        value={vehicleData.has57aGutachten}
                        menuItems={[
                          { label: t('yes'), value: true },
                          { label: t('no'), value: false },
                        ]}
                        onChange={(value) =>
                          updateItem({
                            variables: {
                              args: {
                                _id: itemId,
                                vehicleData: { has57aGutachten: value ?? null },
                              },
                            },
                          })
                        }
                      />
                    </td>
                  </tr>
                  <tr style={{ width: '100%' }}>
                    <td style={{ width: '40%' }}>
                      <p>
                        {t('second_key')}
                        {showRequiredField && (
                          <span className={styles.alert}>*</span>
                        )}
                        :
                      </p>
                    </td>
                    <td style={{ display: 'flex', alignItems: 'center' }}>
                      <SelectOptionInput
                        hasNone
                        value={vehicleData.hasSecondKey}
                        menuItems={[
                          { label: t('yes'), value: true },
                          { label: t('no'), value: false },
                        ]}
                        onChange={(value) =>
                          updateItem({
                            variables: {
                              args: {
                                _id: itemId,
                                vehicleData: { hasSecondKey: value ?? null },
                              },
                            },
                          })
                        }
                      />
                    </td>
                  </tr>
                  {deal.company.carPawnSettings?.includeLicensePlate && (
                    <tr style={{ width: '100%' }}>
                      <td style={{ width: '40%' }}>
                        <p>
                          {t('license_plate')}
                          {showRequiredField && (
                            <span className={styles.alert}>*</span>
                          )}
                          :
                        </p>
                      </td>
                      <td style={{ display: 'flex', alignItems: 'center' }}>
                        <TextInput
                          error={licensePlate.error}
                          value={licensePlate.value}
                          onChange={onChangeLicensePlate}
                          inputWidth={'300px'}
                        />
                        {!licensePlate.error && (
                          <div
                            onClick={() =>
                              updateItem({
                                variables: {
                                  args: {
                                    _id: itemId,
                                    vehicleData: {
                                      licensePlate: licensePlate.value,
                                    },
                                  },
                                },
                              })
                            }
                            className={`o-media__fixed u-inline-block ${styles.saveIcon}`}
                          >
                            <ReactSVG
                              className={`${styles.infoIcon} u-pl-15 u-pb-15`}
                              src={SaveIcon}
                            />
                          </div>
                        )}
                      </td>
                    </tr>
                  )}
                  {deal.company.carPawnSettings?.includeSpareTireCount && (
                    <tr style={{ width: '100%' }}>
                      <td style={{ width: '40%' }}>
                        <p>
                          {t('spare_tires_count')}
                          {showRequiredField && (
                            <span className={styles.alert}>*</span>
                          )}
                          :
                        </p>
                      </td>
                      <td style={{ display: 'flex', alignItems: 'center' }}>
                        <TextInput
                          type={'number'}
                          error={spareTireCount.error}
                          value={spareTireCount.value}
                          onChange={onChangeSpareTireCount}
                          inputWidth={'300px'}
                        />
                        {!spareTireCount.error && (
                          <div
                            onClick={() =>
                              updateItem({
                                variables: {
                                  args: {
                                    _id: itemId,
                                    vehicleData: {
                                      spareTireCount: spareTireCount.value,
                                    },
                                  },
                                },
                              })
                            }
                            className={`o-media__fixed u-inline-block ${styles.saveIcon}`}
                          >
                            <ReactSVG
                              className={`${styles.infoIcon} u-pl-15 u-pb-15`}
                              src={SaveIcon}
                            />
                          </div>
                        )}
                      </td>
                    </tr>
                  )}
                </>
              )}

              {dealItem &&
                dealItem.answers &&
                isElectronic(dealItem.itemCategory) &&
                isUsedCondition(answeredQuestions) && (
                  <tr>
                    <td>
                      {t('used_grade_condition')}:
                      <Info
                        className={'o-media__fixed u-inline-block'}
                        svgClassName={`${styles.infoUsedGradeIcon} u-pl-5 u-pr-5`}
                        infoText={usedGradeMenuItems
                          .map((option) => {
                            return (
                              '<div style="padding-bottom: 5px"><b>' +
                              t(option.value) +
                              '</b>' +
                              ':  ' +
                              t(`${option.value}_INFO`) +
                              '</div>'
                            )
                          })
                          .join('')}
                      />
                    </td>
                    <td style={{ display: 'flex' }}>
                      <SelectOptionInput
                        disabled={isGuestUser()}
                        value={usedGrade}
                        onChange={(value) =>
                          setUsedGrade(
                            value.length > 0
                              ? (value as EUsedGrade)
                              : undefined,
                          )
                        }
                        menuItems={usedGradeMenuItems}
                      />
                      {usedGrade && (
                        <Info
                          className={'o-media__fixed u-inline-block'}
                          svgClassName={`${styles.infoUsedGradeIcon} u-pl-5 u-pr-5`}
                          infoText={t(`${usedGrade}_INFO`)}
                        />
                      )}
                      {usedGrade !== item.usedGrade && (
                        <ReactSVG
                          hidden={item.vehicleData ? true : false}
                          className={`${styles.saveIcon} u-pl-15`}
                          src={SaveIcon}
                          onClick={() => onSaveUsedGrade(usedGrade)}
                        />
                      )}
                    </td>
                  </tr>
                )}

              {coinsAndBarsCategoryIds.includes(
                item.dealsHistory[item.dealsHistory.length - 1].itemCategoryId,
              ) && (
                <tr style={{ width: '100%' }}>
                  <td style={{ width: '40%' }}>{t('investment_gold')}:</td>
                  <td style={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox
                      style={{ marginTop: '1rem' }}
                      id="anlagegold"
                      disabled={item.vehicleData ? true : false}
                      checked={item && !!isInvestmentGold}
                      onChange={(e) => setIsInvestmentGold(e.target.checked)}
                    />
                    <ReactSVG
                      hidden={item.vehicleData ? true : false}
                      className={`${styles.infoIcon} u-pl-15`}
                      src={SaveIcon}
                      onClick={() =>
                        onChangeIsInvestmentGold(!!isInvestmentGold)
                      }
                    />
                  </td>
                </tr>
              )}

              {isDealClosed(deal) &&
                deal.dropoffData?.transportMode === ETransportMode.Auction && (
                  <tr style={{ width: '100%' }}>
                    <td style={{ width: '40%' }}>
                      {t('check_after_auction')}:
                    </td>
                    <td style={{ display: 'flex', alignItems: 'center' }}>
                      <Checkbox
                        style={{ marginTop: '1rem' }}
                        id="checkedAfterAuction"
                        disabled={item.vehicleData ? true : false}
                        checked={item && !!checkedAfterAuction}
                        onChange={(e) =>
                          setCheckedAfterAuction(e.target.checked)
                        }
                      />
                      <ReactSVG
                        hidden={item.vehicleData ? true : false}
                        className={`${styles.infoIcon} u-pl-15`}
                        src={SaveIcon}
                        onClick={() =>
                          onChangeCheckedAfterAuction(!!checkedAfterAuction)
                        }
                      />
                    </td>
                  </tr>
                )}
            </tbody>
          </table>

          <table className={`${styles.table}`}>
            <tbody>
              {parentCategories &&
                parentCategories.length > 0 &&
                parentCategories[0].name === 'Taschen' && (
                  <Fragment>
                    <tr style={{ width: '100%' }}>
                      <td style={{ width: '40%' }}>
                        {t('entrupy_upload_link')}:
                      </td>
                      <td style={{ display: 'flex', alignItems: 'center' }}>
                        <TextField
                          disabled={isGuestUser()}
                          value={entrupyCertificateUrl ?? ''}
                          onChange={(e) =>
                            setEntrupyCertificateUrl(e.target.value)
                          }
                        />
                        {!isGuestUser() && (
                          <>
                            {!entrupyCertificateUrl && (
                              <Button
                                variant="contained"
                                onClick={() => setEntrupyCertificateUrl('N/A')}
                                className="u-mt-sm u-mr-sm u-ml-sm u-mb-sm"
                                classes={{ root: styles.noteButton }}
                              >
                                {t('not_available')}
                              </Button>
                            )}
                            <div
                              onClick={() =>
                                onChangeEntrupyCertificate(
                                  entrupyCertificateUrl ?? null,
                                )
                              }
                              className={`o-media__fixed u-inline-block ${styles.saveIcon}`}
                            >
                              <ReactSVG
                                className={`${styles.infoIcon} u-pl-15`}
                                src={SaveIcon}
                              />
                            </div>
                          </>
                        )}
                      </td>
                    </tr>
                  </Fragment>
                )}
              {parentCategories &&
                parentCategories.length > 0 &&
                parentCategories[0].name === 'Uhren' && (
                  <Fragment>
                    <tr style={{ width: '100%' }}>
                      <td style={{ width: '40%' }}>
                        {t('verification_checklist.watch_verified_by')}:
                      </td>
                      <td style={{ display: 'flex', alignItems: 'center' }}>
                        <TextField
                          disabled={isGuestUser()}
                          value={checkedBy ?? ''}
                          onChange={(e) => setCheckedBy(e.target.value)}
                        />
                        {!isGuestUser() && (
                          <>
                            {!checkedBy && (
                              <Button
                                variant="contained"
                                onClick={() => setCheckedBy('N/A')}
                                className="u-mt-sm u-mr-sm u-ml-sm u-mb-sm"
                                classes={{ root: styles.noteButton }}
                              >
                                {t('not_available')}
                              </Button>
                            )}
                            <div
                              onClick={() =>
                                onChangeCheckedBy(checkedBy ?? null)
                              }
                              className={`o-media__fixed u-inline-block ${styles.saveIcon}`}
                            >
                              <ReactSVG
                                className={`${styles.infoIcon} u-pl-15`}
                                src={SaveIcon}
                              />
                            </div>
                          </>
                        )}
                      </td>
                    </tr>
                    <tr style={{ width: '100%' }}>
                      <td style={{ width: '40%' }}>
                        {t('verification_checklist.watch_verified_at')}:
                      </td>
                      <td style={{ width: '60%' }}>
                        <KeyboardDatePicker
                          className={`${styles.datePicker} u-ml-5`}
                          clearable
                          format="DD.MM.YYYY"
                          value={dayjs(checkedAt)}
                          onChange={(date) =>
                            onDateChange(date, item, 'checkedAt')
                          }
                        />
                        {!isGuestUser() && (
                          <div
                            onClick={() => onChangeCheckedAt(checkedAt ?? null)}
                            className={`o-media__fixed u-inline-block ${styles.saveIcon}`}
                          >
                            <ReactSVG
                              className={`${styles.infoIcon} u-pl-15`}
                              src={SaveIcon}
                            />
                          </div>
                        )}
                      </td>
                    </tr>
                  </Fragment>
                )}
            </tbody>
          </table>
          {showUsedGradeMissing() && (
            <div>
              <div className={styles.heading}>
                {t('verification_checklist.verification_checklist')}
              </div>
              <CloseIcon className={styles.redCross} />
              {t('verification_checklist.use_grade_mandatory')}
            </div>
          )}
          {item.note && (
            <div className={`u-block u-mt-20`}>
              {t('item_customer_note')}:
              <br />
              <br />
              <TextareaAutosize
                rowsMin={5}
                style={{ width: 500 }}
                disabled
                value={item.note ?? ''}
                name="note"
              />
            </div>
          )}
        </Paper>
      </ExpansionBox>

      <ExpansionBox
        title={t('damage_case_personal_use')}
        name={'expandedDamageCaseAndPersonalUse'}
        openAtLoad={
          expansionBoxFilter.values.expandedDamageCaseAndPersonalUse ?? false
        }
        onClickBox={(value, name) => handleExpansionBoxes(value, name)}
      >
        <Paper className="u-p-sm u-12/12 u-mb-sm">
          <table className={`${styles.table}`}>
            <tbody>
              <tr style={{ width: '100%' }}>
                <td style={{ width: '40%', marginTop: '16px' }}>
                  <p style={{ marginTop: '1rem' }}>{t('damage_case')}:</p>
                </td>
                <td style={{ width: '60%' }}>
                  <Checkbox
                    readOnly
                    id="damagecase"
                    style={{ marginTop: '1rem' }}
                    disabled={true}
                    checked={!!item.isDamageCase}
                    // Setting an Item to Damage Case is just by Not_Sellable Status
                    // Unsetting from Damage Case is just by removing Not_Sellable or by Read_To_Sell
                    // onChange={e => damageCase.setProperty('isDamageCase', e.target.checked)}
                  />
                </td>
              </tr>

              <tr style={{ width: '100%', color: 'gray', fontSize: 'small' }}>
                <td style={{ width: '40%' }}>{t('from')}</td>
                <td style={{ width: '60%', paddingLeft: '0.9rem' }}>
                  {item.damageCasePeriods && item.damageCasePeriods.length > 0
                    ? dayjs(item.damageCasePeriods[0].from).format('DD.MM.YYYY')
                    : '-'}
                </td>
              </tr>
              <tr style={{ width: '100%', color: 'gray', fontSize: 'small' }}>
                <td style={{ width: '40%' }}>{t('to')}</td>
                <td style={{ width: '60%', paddingLeft: '0.9rem' }}>
                  {item.damageCasePeriods &&
                  item.damageCasePeriods.length > 0 &&
                  item.damageCasePeriods[0].to
                    ? dayjs(item.damageCasePeriods[0].to).format('DD.MM.YYYY')
                    : '-'}
                </td>
              </tr>

              <tr style={{ width: '100%' }}>
                <td style={{ width: '40%', marginTop: '16px' }}>
                  <p style={{ marginTop: '1rem' }}>{t('self_use')}:</p>
                </td>
                <td style={{ width: '60%' }}>
                  <Checkbox
                    readOnly
                    style={{ marginTop: '1rem' }}
                    id="personaluse"
                    disabled={true}
                    checked={!!item.isForPersonalUse}
                    // Setting an Item to Damage Case is just by Not_Sellable Status
                    // Unsetting from Damage Case is just by removing Not_Sellable or by Read_To_Sell
                    // onChange={e => forPersonalUse.setProperty('isForPersonalUse', e.target.checked)}
                  />
                </td>
              </tr>

              <tr style={{ width: '100%', color: 'gray', fontSize: 'small' }}>
                <td style={{ width: '40%' }}>{t('from')}</td>
                <td style={{ width: '60%', paddingLeft: '0.9rem' }}>
                  {item.personalUsePeriods && item.personalUsePeriods.length > 0
                    ? dayjs(item.personalUsePeriods[0].from).format(
                        'DD.MM.YYYY',
                      )
                    : '-'}
                </td>
              </tr>
              <tr style={{ width: '100%', color: 'gray', fontSize: 'small' }}>
                <td style={{ width: '40%' }}>{t('to')}</td>
                <td style={{ width: '60%', paddingLeft: '0.9rem' }}>
                  {item.personalUsePeriods &&
                  item.personalUsePeriods.length > 0 &&
                  item.personalUsePeriods[0].to
                    ? dayjs(item.personalUsePeriods[0].to).format('DD.MM.YYYY')
                    : '-'}
                </td>
              </tr>
            </tbody>
          </table>
        </Paper>
      </ExpansionBox>

      <ExpansionBox
        title={t('notification_notes')}
        name={'expandedNotes'}
        openAtLoad={expansionBoxFilter.values.expandedNotes ?? false}
        onClickBox={(value, name) => handleExpansionBoxes(value, name)}
      >
        <Paper className="u-p-sm u-12/12 u-mb-sm">
          <NoteContainer
            notes={item.notes || []}
            onAddNote={onAddItemNote}
            onUpdateNote={onUpdateItemNote}
          />
        </Paper>
      </ExpansionBox>

      <ExpansionBox
        title={t('uploads_overview')}
        name={'expandedUploads'}
        openAtLoad={expansionBoxFilter.values.expandedUploads ?? false}
        onClickBox={(value, name) => handleExpansionBoxes(value, name)}
      >
        <Paper className="u-p-sm u-12/12 u-mb-sm">
          <ItemUploadsOverview
            item={item}
            itemCategory={currentCategories[0]}
            bookingNumber={deal.bookingNumber}
          />
        </Paper>
      </ExpansionBox>

      <ExpansionBox
        title={t('item_status')}
        name={'expandedItemStatus'}
        openAtLoad={expansionBoxFilter.values.expandedItemStatus ?? false}
        onClickBox={(value, name) => handleExpansionBoxes(value, name)}
      >
        <Paper className="u-p-sm u-12/12 u-mb-sm">
          <ItemStatus
            deal={deal}
            item={item}
            valorizationHistories={
              dealItem?.itemValorizationCalculation?.valorizationHistory
            }
            onChangeItemStatus={onChangeItemStatus}
            updateStatusLoading={updateStatusLoading}
            onRemoveLatestEvent={onRemoveLatestEvent}
            isLockedSmartPhone={isLockedSmartPhone}
          />
        </Paper>
      </ExpansionBox>

      <ExpansionBox
        title={t('valuation')}
        name={'expandedValuation'}
        openAtLoad={expansionBoxFilter.values.expandedValuation ?? false}
        onClickBox={(value, name) => handleExpansionBoxes(value, name)}
      >
        <Paper className="u-p-sm u-12/12 u-mb-sm">
          <ItemValuesTable
            rows={itemValuesRows}
            columns={itemValuesColumns}
            className={styles.itemValuesTable}
          />
        </Paper>
      </ExpansionBox>

      {dealItem && (
        <Fragment>
          {!!onSellEvent && !auctionItemSoldToPerson && (
            <ExpansionBox
              title={t('valorization.label')}
              name={'expandedValorization'}
              openAtLoad={
                expansionBoxFilter.values.expandedValorization ?? false
              }
              onClickBox={(value, name) => handleExpansionBoxes(value, name)}
            >
              <Paper className="u-p-sm u-12/12 u-mb-sm">
                <ItemValorizationDetails
                  deal={deal}
                  timezone={defaultTimezone}
                  item={dealItem}
                  directPurchaseFactor={calcDirectPurchaseFactor(deal)}
                  businessUnit={deal.businessUnit}
                />
              </Paper>
            </ExpansionBox>
          )}

          <AuctionItem dealItem={dealItem} auction={auction} />

          {!!onSellEvent && (
            <ExpansionBox
              title={t('valorization_history')}
              name={'expandedValorizationHistory'}
              openAtLoad={
                expansionBoxFilter.values.expandedValorizationHistory ?? false
              }
              onClickBox={(value, name) => handleExpansionBoxes(value, name)}
            >
              <Paper className="u-p-sm u-12/12 u-mb-sm">
                <ValorizationHistory
                  deal={deal}
                  item={dealItem}
                  itemWithEvents={item}
                />
              </Paper>
            </ExpansionBox>
          )}

          <ExpansionBox
            title={t('storage.storage_facilities')}
            name={'expandedStorage'}
            openAtLoad={expansionBoxFilter.values.expandedStorage ?? false}
            onClickBox={(value, name) => handleExpansionBoxes(value, name)}
          >
            <Paper className="u-p-sm u-12/12 u-mb-sm">
              {deal.events.some(
                (event) => event.__typename === 'DealVerifiedEvent',
              ) || isCar ? (
                <>
                  <SelectOptionInput
                    disabled={isGuestUser()}
                    value={item.storageFacilityId}
                    onChange={(value) =>
                      typeof value === 'string'
                        ? onChangeStorageFacilityId(value)
                        : undefined
                    }
                    label={t('storage.storage_facilities')}
                    menuItems={storageFacilities.map((sf) => ({
                      label: sf.name,
                      value: sf._id,
                    }))}
                    hasAsterisk={showRequiredField}
                  />
                  {storageFacility && (
                    <StorageUnitsConfiguration
                      storageFacilityId={storageFacility?._id}
                      item={item}
                      controlBarStatus={false}
                      storageHistory={item.storageHistory}
                      items={deal.items}
                      deal={deal}
                    />
                  )}

                  {item.storageHistory && item.storageLabel && (
                    <>
                      <Typography
                        component="div"
                        className="u-pt-sm"
                        variant="h6"
                        gutterBottom
                        classes={{ root: styles.title }}
                      >
                        {t('storage.storage_history')}
                      </Typography>
                      <StorageHistory
                        storageHistory={item.storageHistory}
                        employees={employees}
                        storageFacilities={storageFacilities}
                        timezone={defaultTimezone}
                        storageLabel={item.storageLabel}
                        storageUnits={storageUnits}
                      />
                    </>
                  )}
                </>
              ) : (
                <p
                  style={{
                    boxSizing: 'border-box',
                    display: 'inline-block',
                    padding: '0.5vh',
                    paddingLeft: 0,
                    paddingRight: 16 + 'px',
                  }}
                >
                  {t('check_verified_deal')}
                </p>
              )}
            </Paper>
          </ExpansionBox>
        </Fragment>
      )}
      <NotePopup
        noteBuilds={noteBuilds}
        open={openNotePopup}
        onClose={() => setOpenNotePopup(false)}
      />
    </Paper>
  )
}

export default ItemBlock
