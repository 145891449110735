// Note: We have this links also configured in api and website.
export const cashyDocumentLinks = {
  termsCashyLink:
    'https://storage.googleapis.com/cashy-api-v2-content/documents/AGB-Cashy-GmbH.pdf',
  termsCashyAutomotiveLink:
    'https://storage.googleapis.com/cashy-api-v2-content/documents/Gescha%CC%88ftsordnung_Cashy%20Automotive.pdf',
  feesCashyLink:
    'https://storage.googleapis.com/cashy-api-v2-content/documents/Geb%C3%BChrentarif.pdf',
  feesCashyAutomotiveLink:
    'https://storage.googleapis.com/cashy-api-v2-content/documents/Geb%C3%BChrentarif_Cashy%20Automotive_Kfz.pdf',
}

export enum UppyError {
  FILE_DUPLICATION = 'FILE_DUPLICATION',
  EXCEED_MAX_FILE_SIZE = 'EXCEED_MAX_FILE_SIZE',
  FILE_TYPE_UNALLOWED = 'FILE_TYPE_UNALLOWED',
}

export const MAX_IMAGE_SIZE_MB = 20

export const ALLOWED_FILE_TYPES = ['image/*', '.jpg', '.jpeg', '.png', '.gif']

export const PICEA_TEST_MINIMUM = {
  Tablets: 200,
  Smartphones: 100,
}
