import { ApolloQueryResult } from '@apollo/client'
import styled from '@emotion/styled'
import dayjs from 'dayjs'
import { isEqual } from 'lodash'
import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Message } from 'primereact/message'
import { useCallback, useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { ReactSVG } from 'react-svg'
import Info from '@/components/Info'
import { Context } from '@/context'
import { useAddDealItemsToStorageUnit } from '@/domains/deals/hooks/addDealItemsToStorageUnit'
import {
  useGetActiveStorageFacilities,
  useGetItems,
  useGetStorageUnits,
} from '@/domains/items/hooks'
import { useMutationShowingErrors, useShowConfirmPopup } from '@/hooks'
import InfoIcon from '@/images/07-info.svg'
import {
  AddItemToStorageUnitArgs,
  Deal,
  EManualPaymentType,
  EStorageStatus,
  ItemStorageHistoryEntry,
  ItemValuesEntry,
  Maybe,
  Query,
  QueryGetDealByIdArgs,
  StorageUnit,
} from '@/schemaTypes'
import { getLatestEventForStatus, isDealVerified } from '@/utils/deal'
import {
  displayLocalAmount,
  paymentTypeUnion,
  printLocalFloat,
} from '@/utils/misc'
import AddDealItemsStorageUnit from './AddDealItemsStorageUnit'

export interface ItemOverviewTableRow {
  id: string
  title: string
  productRef: string
  storageLabel: string
  valuesSelected?: ItemValuesEntry
  valuesVerified?: ItemValuesEntry
  valuesClosed?: ItemValuesEntry
  storageFacilityId: string
  storageHistory?: Maybe<Array<ItemStorageHistoryEntry>>
}

export type GroupItemsUpdateArgs = Pick<
  AddItemToStorageUnitArgs,
  'storageFacilityId' | 'storageUnits' | 'note' | 'setDealToPayedAndStored'
>

export interface ItemOverviewTableProps {
  items?: ItemOverviewTableRow[]
  deleteItem?: (itemId: string) => void
  deal?: Deal
  refetchDeal: (
    variables?: Partial<QueryGetDealByIdArgs> | undefined,
  ) => Promise<ApolloQueryResult<{ getDealById: Query['getDealById'] }>>
  hasCustomItem: boolean
  isAddItemVisible: boolean
}

export function ItemOverviewTable({
  items,
  deleteItem,
  deal,
  refetchDeal,
  hasCustomItem,
  isAddItemVisible,
}: ItemOverviewTableProps) {
  const { closeConfirmModal, showInfo } = useContext(Context)
  const [selectedItems, setSelectedItems] = useState([])
  const [
    selectedItemsForGroupStorageUpdate,
    setSelectedItemsForGroupStorageUpdate,
  ] = useState<string[]>([])
  const { t } = useTranslation()
  const { storageUnits } = useGetStorageUnits()
  const { storageFacilities } = useGetActiveStorageFacilities()

  const queryResults = useGetItems({
    variables: {
      opts: {
        filter: {
          ids: selectedItemsForGroupStorageUpdate,
        },
      },
    },
    skip: !selectedItemsForGroupStorageUpdate.length,
  })

  const validForStoreMultipleItems =
    queryResults.items.every((item) => item.isValuable) ||
    queryResults.items.every((item) => !item.isValuable)

  const showConfirmItemsStorageUpdatePopup = useShowConfirmPopup({
    actionText: t('save'),
  })

  const addDealItemsToStorage = useMutationShowingErrors({
    mutation: useAddDealItemsToStorageUnit(),
    successMessage: t('selected_items_added_to_storage_unit'),
    closeDialogWhenMutationStarts: true,
  })

  const storageGroupUpdateDisabled = useMemo(
    () => deal && !isDealVerified(deal),
    [deal],
  )

  const storageUnitsMap = storageUnits.reduce(
    (storageUnitsMap: Record<string, StorageUnit>, storageUnit) => {
      storageUnitsMap[storageUnit._id] = storageUnit

      return storageUnitsMap
    },
    {},
  )

  const eventPayedAndStoredPossible = useMemo(() => {
    return (
      deal &&
      deal.itemsIds.length ===
        selectedItemsForGroupStorageUpdate.length +
          deal.items.filter((c) =>
            Boolean(getStorageHistoryString(storageUnitsMap, c.storageHistory)),
          ).length
    )
  }, [selectedItemsForGroupStorageUpdate, deal, storageUnitsMap])

  const canDeleteItem = useMemo(
    () =>
      deal &&
      !getLatestEventForStatus(deal, 'DealSoldExternEvent') &&
      !getLatestEventForStatus(deal, 'DealExtensionConfirmedEvent') &&
      deal &&
      deal.items.length > 1,
    [deal],
  )

  const handleStoreItems = () => {
    showConfirmItemsStorageUpdatePopup({
      state: {
        setDealToPayedAndStored:
          eventPayedAndStoredPossible &&
          paymentTypeUnion(deal?.payoutData?.paymentType) ===
            EManualPaymentType.Cash,
        storageFacilityId:
          items && selectedItemsForGroupStorageUpdate.length > 0
            ? items.find((c) =>
                isEqual(c.id, selectedItemsForGroupStorageUpdate[0]),
              )?.storageFacilityId
            : '',
      },
      title: t('store_items'),
      component: (state: GroupItemsUpdateArgs, setState) => {
        if (!validForStoreMultipleItems) {
          return null
        }

        return (
          <AddDealItemsStorageUnit
            state={state}
            setState={setState}
            item={queryResults.items[0]}
            eventPayedAndStoredPossible={Boolean(eventPayedAndStoredPossible)}
            storageFacilities={storageFacilities}
          />
        )
      },
      action: async (state: GroupItemsUpdateArgs) => {
        closeConfirmModal()
        await addDealItemsToStorage({
          variables: {
            args: {
              dealId: deal?._id,
              itemIds: selectedItemsForGroupStorageUpdate,
              storageFacilityId: state.storageFacilityId,
              storageUnits: state.storageUnits,
              note: state.note,
              setDealToPayedAndStored: state.setDealToPayedAndStored,
              storageDate: dayjs().toDate(),
            },
          },
        }).then(() => {
          refetchDeal()
          setSelectedItemsForGroupStorageUpdate([])
        })
      },
    })
  }

  const storageLabelColumn = useCallback(
    (rowData) => (
      <StorageLabelLinkStyled
        className="cursor-pointer"
        id={`DEAL_ITEM_VIEW_ITEM_DETAIL_${rowData.id}`}
        to={`/inApp/items/${rowData.id}`}
      >
        {rowData.storageLabel}
      </StorageLabelLinkStyled>
    ),
    [],
  )

  const storageLocationColumn = useCallback(
    (rowData) =>
      deal?.events.some((event) => event.__typename === 'DealClosedEvent') ? (
        <LastStoragelabel>
          {' '}
          {getLastStorageHistoryString(
            storageUnitsMap,
            t,
            rowData.storageHistory,
          )}
        </LastStoragelabel>
      ) : (
        getStorageHistoryString(storageUnitsMap, rowData.storageHistory)
      ),
    [deal?.events, storageUnitsMap, t],
  )

  const verifiedColumn = useCallback(
    (rowData) => displayLocalAmount(rowData.valuesVerified?.loanToValue),
    [],
  )

  const loanToValueColumn = useCallback(
    (rowData) =>
      rowData.valuesClosed
        ? printLocalFloat(rowData.valuesClosed.loanToValue)
        : '-',
    [],
  )

  const tableActionsTemplate = useCallback(
    (rowData) => {
      return (
        <div className="flex justify-end">
          <Link
            to={`/inApp/items/${rowData._id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button icon="pi pi-external-link" severity="secondary" text />
          </Link>
          {canDeleteItem && (
            <Button
              icon="pi pi-trash"
              severity="danger"
              onClick={() => deleteItem(rowData.id)}
              text
              tooltipOptions={{ position: 'bottom' }}
            />
          )}
        </div>
      )
    },
    [deleteItem, canDeleteItem],
  )

  const footerTemplate = useCallback(
    () => (
      <div className="flex items-center">
        <Link
          to={`/inApp/deals/${deal._id}/addItem`}
          style={{ pointerEvents: hasCustomItem ? 'none' : 'auto' }}
        >
          <Button
            label={'Add Item'}
            severity="secondary"
            text
            icon="pi pi-plus"
            size="small"
            disabled={hasCustomItem}
          />
        </Link>
        <Info
          className={'o-media__fixed u-inline-block'}
          svgClassName={`u-pl-5 u-pr-5`}
          infoText={'infoText'}
        />

        {hasCustomItem && (
          <>
            <ErrorText className="mr-2">
              {t('add_normal_item_warning')}
            </ErrorText>
            <ReactSVG
              src={InfoIcon}
              style={{ marginLeft: 2, width: 17, cursor: 'pointer' }}
              onClick={() => showInfo(t('add_normal_item_warning_explanation'))}
            />
          </>
        )}
      </div>
    ),
    [hasCustomItem, deal, showInfo, t],
  )

  const handleSelectItem = useCallback(
    (rows) => {
      setSelectedItemsForGroupStorageUpdate(
        rows
          ?.filter(
            (row) =>
              !getStorageHistoryString(storageUnitsMap, row.storageHistory),
          )
          ?.map((c) => c.id),
      )
      setSelectedItems(rows)
    },
    [storageUnitsMap],
  )

  return (
    <>
      <DataTableStyled
        value={items}
        selectionMode={'checkbox'}
        selection={selectedItems}
        onSelectionChange={(e) => handleSelectItem(e.value)}
        dataKey="id"
        tableStyle={{ minWidth: '50rem' }}
        footer={isAddItemVisible && footerTemplate}
        className="my-4"
        isDataSelectable={({ data }) =>
          !(
            storageGroupUpdateDisabled ||
            Boolean(
              getStorageHistoryString(storageUnitsMap, data.storageHistory),
            )
          )
        }
      >
        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} />
        <Column field="productRef" header={t('product_reference')} />
        <Column body={storageLabelColumn} header={t('storage.label')} />
        <Column field="title" header={t('item_description')} />
        <Column body={storageLocationColumn} header={t('storage.location')} />
        <Column body={verifiedColumn} header={t('verified')} />
        <Column
          body={loanToValueColumn}
          header={`${t('loan_to_value')} ${t('closed')}`}
        />
        <Column style={{ width: '50px' }} body={tableActionsTemplate} />
      </DataTableStyled>

      <div className="flex justify-start">
        <Button
          severity="secondary"
          id={'deal-item_store_items-button'}
          onClick={handleStoreItems}
          disabled={
            !selectedItemsForGroupStorageUpdate.length ||
            !validForStoreMultipleItems ||
            queryResults.queryResult.loading
          }
        >
          {t('store_items')}
        </Button>

        {(queryResults?.items.length ?? 0) > 0 &&
          !validForStoreMultipleItems && (
            <div className="ml-3">
              <Message
                severity="warn"
                text={t('valid_store_multiple_items_warning')}
              />
            </div>
          )}
      </div>
    </>
  )
}

const formatStorageLocation = (
  storage: ItemStorageHistoryEntry,
  storageUnitsMap: Record<string, StorageUnit>,
): string => {
  const storageUnitIds: string[] = ['lvl1', 'lvl2', 'lvl3', 'lvl4']
    .map((level) => storage.storageUnits[level])
    .filter(Boolean)

  return storageUnitIds.map((id) => storageUnitsMap[id]?.name).join(' -> ')
}

const getStorageHistoryString = (
  storageUnitsMap: Record<string, StorageUnit>,
  storageHistory?: Maybe<ItemStorageHistoryEntry[]>,
) => {
  if (!storageHistory?.length) return null

  const currentStorage = storageHistory[0]

  if (currentStorage.storageStatus !== EStorageStatus.AddedToStorage)
    return null

  const storageUnitLocation = formatStorageLocation(
    currentStorage,
    storageUnitsMap,
  )

  return storageUnitLocation
}

const getLastStorageHistoryString = (
  storageUnitsMap: Record<string, StorageUnit>,
  t: (string) => void,
  storageHistory?: Maybe<ItemStorageHistoryEntry[]>,
) => {
  if (!storageHistory?.length) return null
  const lastStorage = storageHistory.find(
    (storage) => storage.storageStatus === EStorageStatus.AddedToStorage,
  )
  if (!lastStorage) return null
  const storageUnitLocation = formatStorageLocation(
    lastStorage,
    storageUnitsMap,
  )

  return `${t('last_storage_space')} ${storageUnitLocation}`
}

const StorageLabelLinkStyled = styled(Link)`
  color: #6366f1;
`

const LastStoragelabel = styled.label`
  color: rgb(150, 150, 150);
`

const ErrorText = styled.p`
  color: var(--red-400, #ff6259);
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 0.875rem;
`

const DataTableStyled = styled(DataTable)`
  &&& {
    border: 1px solid #dee2e6;
    border-radius: 0.375rem;

    .p-datatable-wrapper {
      border-radius: 0.375rem;
    }
    .p-datatable-footer {
      padding: 0;
    }
  }
`

export default ItemOverviewTable
