import { Typography } from '@material-ui/core'
import DoneIcon from '@material-ui/icons/CheckCircleRounded'
import ErrorIcon from '@material-ui/icons/Error'
import { MenuItem, Select, Tooltip } from '@mui/material'
import * as React from 'react'
import { Fragment, useState } from 'react'
import { FunctionComponent } from 'react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useGetDealById } from '@/domains/deals/hooks'
import {
  EShippingLabelDirection,
  EShippingLabelEntity,
  EShippingLabelFileSource,
  EStandardShipmentType,
  Employee,
  Item,
  Scalars,
  ShippingLabel,
  ShippingLabelCreateArgs,
  Shop,
  ValorizationHistoryEntry,
} from '@/schemaTypes'
import { createIAddress } from '@/utils/address'
import styles from './ShippingLabel.module.scss'
import ShippingLabelGenerate from './ShippingLabelGenerate'
import ShippingLabelHistory from './ShippingLabelHistory'
import ShippingLabelUpload from './ShippingLabelUpload'

export interface IShipBetweenShopsByCashyData {
  cashyEmployeeId?: string | null
  destinationShopId?: string | null
}

interface ShippingLabelProps {
  dealId: Scalars['ObjectId']
  item?: Item
  valorizationHistories?: ValorizationHistoryEntry[]
  entityType: EShippingLabelEntity
  directionType: EShippingLabelDirection
  logisticServiceProviders: string[]
  shippingLabelCreateArgs: ShippingLabelCreateArgs | undefined
  setShippingLabelCreateArgs
  shippingLabelCreateArgsIsValid: boolean
  setShippingLabelCreateArgsIsValid
  customerEmailAddress?: string | null
  allowCODPayment?: boolean
  betweenShops?: boolean
  onChangeDestinationShopId?: (shopId: string | undefined) => void
  shipBetweenShopsByCashyOptions?: {
    shipBetweenShopsByCashyData: IShipBetweenShopsByCashyData
    setShipBetweenShopsByCashyData: React.Dispatch<
      React.SetStateAction<IShipBetweenShopsByCashyData>
    >
    employees?: Array<Employee>
    shops?: Array<Shop>
  }
}

const ShippingLabelComponent: FunctionComponent<ShippingLabelProps> = (
  props,
) => {
  const {
    dealId,
    item,
    entityType,
    directionType,
    shippingLabelCreateArgs,
    setShippingLabelCreateArgs,
    logisticServiceProviders,
    shippingLabelCreateArgsIsValid,
    setShippingLabelCreateArgsIsValid,
    customerEmailAddress,
    valorizationHistories,
    allowCODPayment,
    betweenShops,
    shipBetweenShopsByCashyOptions,
    onChangeDestinationShopId,
  } = props

  const { t } = useTranslation()

  const [shippingLabelCreateArgsFromAPI, setShippingLabelCreateArgsFromAPI] =
    useState<ShippingLabelCreateArgs>()
  const [
    shippingLabelCreateArgsFromHistory,
    setShippingLabelCreateArgsFromHistory,
  ] = useState<ShippingLabelCreateArgs>()
  const [
    shippingLabelCreateArgsFromUpload,
    setShippingLabelCreateArgsFromUpload,
  ] = useState<ShippingLabelCreateArgs>()
  const [filteredSourceList, setFilteredSourceList] = useState(
    Object.values(EShippingLabelFileSource),
  )
  const generateCapablePartners = [
    EStandardShipmentType.Post,
    EStandardShipmentType.DpdStandard,
  ]

  const { deal } = useGetDealById({
    variables: {
      dealId: dealId,
    },
  })

  useEffect(() => {
    // Here we evaluate the changes on createArgs object to check if it is valid or not
    let objectToCheck: ShippingLabelCreateArgs | undefined = undefined
    if (shippingLabelCreateArgs) {
      if (
        shippingLabelCreateArgs?.shippingLabelFileSource ===
        EShippingLabelFileSource.Generate
      ) {
        objectToCheck = shippingLabelCreateArgsFromAPI
        if (shippingLabelCreateArgsFromAPI)
          setShippingLabelCreateArgs(shippingLabelCreateArgsFromAPI)
      } else if (
        shippingLabelCreateArgs?.shippingLabelFileSource ===
        EShippingLabelFileSource.History
      ) {
        objectToCheck = shippingLabelCreateArgsFromHistory
        if (shippingLabelCreateArgsFromHistory)
          setShippingLabelCreateArgs(shippingLabelCreateArgsFromHistory)
      }
      if (
        shippingLabelCreateArgs?.shippingLabelFileSource ===
        EShippingLabelFileSource.Upload
      ) {
        objectToCheck = shippingLabelCreateArgsFromUpload
        if (shippingLabelCreateArgsFromUpload)
          setShippingLabelCreateArgs(shippingLabelCreateArgsFromUpload)
      }

      if (!objectToCheck) {
        setShippingLabelCreateArgsIsValid(false)
      } else {
        if (!objectToCheck.labelFile) {
          setShippingLabelCreateArgsIsValid(false)
        } else if (!objectToCheck.trackingCode || !objectToCheck.trackingLink) {
          setShippingLabelCreateArgsIsValid(false)
        } else {
          setShippingLabelCreateArgsIsValid(true)
        }
      }
    } else {
      setShippingLabelCreateArgsIsValid(false)
    }
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    shippingLabelCreateArgs,
    shippingLabelCreateArgsFromAPI,
    shippingLabelCreateArgsFromHistory,
    shippingLabelCreateArgsFromUpload,
  ])

  const handleShippingPartnerChange = (newPartner) => {
    // We just clear the Generate and history!
    // Because Upload is not generated yet and can be changed!
    if (
      shippingLabelCreateArgs &&
      (shippingLabelCreateArgs.shippingLabelFileSource ===
        EShippingLabelFileSource.Generate ||
        shippingLabelCreateArgs.shippingLabelFileSource ===
          EShippingLabelFileSource.History)
    ) {
      setShippingLabelCreateArgsFromAPI(undefined)
      setShippingLabelCreateArgsFromHistory(undefined)
    }

    if (!generateCapablePartners.includes(newPartner))
      setFilteredSourceList(
        Object.values(EShippingLabelFileSource).filter(
          (c) => c !== EShippingLabelFileSource.Generate,
        ),
      )
    else setFilteredSourceList(Object.values(EShippingLabelFileSource))

    // We remove every thing except the shippingPartner
    setShippingLabelCreateArgsFromAPI(undefined)
    setShippingLabelCreateArgsFromHistory(undefined)
    setShippingLabelCreateArgsFromUpload(undefined)
    setShippingLabelCreateArgs({ shippingPartner: newPartner })

    if (
      shippingLabelCreateArgs?.shippingPartner !== EStandardShipmentType.Cashy
    ) {
      shipBetweenShopsByCashyOptions?.setShipBetweenShopsByCashyData({
        destinationShopId: null,
        cashyEmployeeId: null,
      })
    }
  }

  const handleFileSourceChange = (newSource: EShippingLabelFileSource) => {
    setShippingLabelCreateArgs({
      ...shippingLabelCreateArgs,
      shippingLabelFileSource: newSource,
    })

    // We remove every thing except the shippingPartner
    setShippingLabelCreateArgsFromAPI(undefined)
    setShippingLabelCreateArgsFromHistory(undefined)
    setShippingLabelCreateArgsFromUpload(undefined)
  }

  const shipBetweenShopsByCashySelected =
    shipBetweenShopsByCashyOptions &&
    shippingLabelCreateArgs?.shippingPartner === EStandardShipmentType.Cashy
  const shipBetweenShopsByCashyDataValid =
    shipBetweenShopsByCashyOptions?.shipBetweenShopsByCashyData
      .destinationShopId &&
    shipBetweenShopsByCashyOptions?.shipBetweenShopsByCashyData.cashyEmployeeId

  if (!deal) return null

  return (
    <Fragment>
      <div className={styles.main}>
        <table>
          <tr>
            <td className={styles.td}>
              <div>
                {t('logistics_partner')}:
                <Select
                  style={{ marginLeft: '1rem', marginBottom: '1rem' }}
                  value={shippingLabelCreateArgs?.shippingPartner}
                  onChange={(e) => handleShippingPartnerChange(e.target.value)}
                  variant="standard"
                >
                  {logisticServiceProviders.map((name) => (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              {shipBetweenShopsByCashySelected ? (
                <>
                  {shipBetweenShopsByCashyOptions!.shops && (
                    <Typography>
                      {t('destination_shop')}:&nbsp;&nbsp;
                      <Select
                        variant="standard"
                        style={{ marginLeft: '1rem', marginBottom: '1rem' }}
                        value={
                          shipBetweenShopsByCashyOptions!
                            .shipBetweenShopsByCashyData?.destinationShopId
                        }
                        onChange={(e) =>
                          shipBetweenShopsByCashyOptions!.setShipBetweenShopsByCashyData(
                            (prev) => ({
                              ...prev,
                              destinationShopId: e.target.value as string,
                            }),
                          )
                        }
                      >
                        {shipBetweenShopsByCashyOptions!.shops.map((option) => (
                          <MenuItem key={option._id} value={option._id}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </Typography>
                  )}

                  {shipBetweenShopsByCashyOptions!.employees && (
                    <Typography>
                      {t('cashy_employee')}:&nbsp;&nbsp;
                      <Select
                        variant="standard"
                        style={{ marginLeft: '1rem', marginBottom: '1rem' }}
                        value={
                          shipBetweenShopsByCashyOptions!
                            .shipBetweenShopsByCashyData?.cashyEmployeeId
                        }
                        onChange={(e) =>
                          shipBetweenShopsByCashyOptions!.setShipBetweenShopsByCashyData(
                            (prev) => ({
                              ...prev,
                              cashyEmployeeId: e.target.value as string,
                            }),
                          )
                        }
                      >
                        {shipBetweenShopsByCashyOptions!.employees.map(
                          (option) => (
                            <MenuItem key={option._id} value={option._id}>
                              {option.firstname} {option.lastname}
                            </MenuItem>
                          ),
                        )}
                      </Select>
                    </Typography>
                  )}
                </>
              ) : (
                <div>
                  {t('shipping_label')}:
                  <Select
                    style={{ marginLeft: '2rem', marginBottom: '1rem' }}
                    value={
                      shippingLabelCreateArgs?.shippingLabelFileSource ?? null
                    }
                    onChange={(e) =>
                      handleFileSourceChange(
                        e.target.value as EShippingLabelFileSource,
                      )
                    }
                    variant="standard"
                  >
                    {filteredSourceList.map((name) => (
                      <MenuItem key={name} value={name}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              )}
            </td>
            <td className={styles.td}>
              {shipBetweenShopsByCashySelected ? (
                <>
                  {shipBetweenShopsByCashyDataValid ? (
                    <span className={styles.check}>
                      <DoneIcon fontSize="large" />
                    </span>
                  ) : (
                    <span className={styles.error}>
                      <Tooltip
                        title={t(
                          'ship_between_shops_by_cashy_data_is_not_valid',
                        )}
                      >
                        <ErrorIcon fontSize="large" />
                      </Tooltip>
                    </span>
                  )}
                </>
              ) : (
                <>
                  {shippingLabelCreateArgsIsValid ? (
                    <span className={styles.check}>
                      <DoneIcon fontSize="large" />
                    </span>
                  ) : (
                    <span className={styles.error}>
                      <Tooltip title={t('shipping_label_is_not_provided')}>
                        <ErrorIcon fontSize="large" />
                      </Tooltip>
                    </span>
                  )}
                </>
              )}
            </td>
          </tr>
        </table>

        {shippingLabelCreateArgs?.shippingLabelFileSource ===
          EShippingLabelFileSource.Generate && (
          <ShippingLabelGenerate
            deal={deal}
            item={item}
            valorizationHistories={valorizationHistories}
            entityType={entityType}
            directionType={directionType}
            shippingPartner={shippingLabelCreateArgs?.shippingPartner}
            shippingLabelCreateArgsFromAPI={shippingLabelCreateArgsFromAPI}
            setShippingLabelCreateArgsFromAPI={
              setShippingLabelCreateArgsFromAPI
            }
            customerEmailAddress={customerEmailAddress}
            allowCODPayment={allowCODPayment}
            betweenShops={betweenShops}
            onChangeDestinationShopId={onChangeDestinationShopId}
          />
        )}

        {shippingLabelCreateArgs?.shippingLabelFileSource ===
          EShippingLabelFileSource.History && (
          <ShippingLabelHistory
            dealId={dealId}
            itemId={item ? item._id : undefined}
            shippingEntity={entityType}
            directionType={directionType}
            shippingPartner={shippingLabelCreateArgs?.shippingPartner}
            shippingLabelCreateArgsFromHistory={
              shippingLabelCreateArgsFromHistory
            }
            setShippingLabelCreateArgsFromHistory={
              setShippingLabelCreateArgsFromHistory
            }
          />
        )}

        {shippingLabelCreateArgs?.shippingLabelFileSource ===
          EShippingLabelFileSource.Upload && (
          <ShippingLabelUpload
            deal={deal}
            item={item}
            directionType={directionType}
            entityType={entityType}
            shippingPartner={shippingLabelCreateArgs?.shippingPartner}
            shippingLabelCreateArgsFromUpload={
              shippingLabelCreateArgsFromUpload
            }
            setShippingLabelCreateArgsFromUpload={
              setShippingLabelCreateArgsFromUpload
            }
          />
        )}
      </div>
    </Fragment>
  )
}

export default ShippingLabelComponent

export const convertShippingLabelModelToCreteArgs = (
  model: ShippingLabel,
  dealId: Scalars['ObjectId'],
  itemId: Scalars['ObjectId'] | undefined,
  entity: EShippingLabelEntity,
  fileSource: EShippingLabelFileSource,
) => {
  const createArgs: ShippingLabelCreateArgs = {
    dealId: dealId,
    itemId: itemId,
    entity: entity,
    shippingLabelFileSource: fileSource,

    shippingPartner: model.shippingPartner,
    direction: model.direction,
    trackingLink: model.trackingLink,
    trackingCode: model.trackingCode,

    senderName: model.senderName,
    senderAddress: createIAddress(model.senderAddress),
    receiverName: model.receiverName,
    receiverAddress: createIAddress(model.receiverAddress),
    labelFile: model.labelFile,
  }

  return createArgs
}
