import { useGetPropertyValueErrorMessages } from '../hooks/useGetPropertyValueErrorMessages'
import {
  ProductAvailableProperty,
  ProductAvailablePropertyTextOptions,
  ProductFormData,
  ProductVariant,
} from '../interfaces'
import { Box, Typography } from '@material-ui/core'
import { FormikErrors } from 'formik'
import { cloneDeep, uniqueId } from 'lodash'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactSVG } from 'react-svg'
import TextInput from '@/components/TextInput'
import AddIcon from '@/images/add.svg'
import DeleteIcon from '@/images/deleteOption.svg'
import {
  ELanguageCode,
  EProductPropertyType,
  ProductPropertyDefinition,
} from '@/schemaTypes'

interface Props {
  property: ProductAvailableProperty
  variants: ProductVariant[]
  availableProperties: ProductAvailableProperty[]
  setFieldValue: <T extends keyof ProductFormData>(
    field: T,
    value: ProductFormData[T],
    shouldValidate?: boolean | undefined,
  ) => Promise<void> | Promise<FormikErrors<ProductFormData>>
}

export function ProductEditAvailableProperty(props: Props) {
  const { property, setFieldValue, availableProperties, variants } = props

  const Component = (() => {
    const propertyDefinition = property.propertyDefinition
    if (!propertyDefinition) return null
    if (propertyDefinition.type === EProductPropertyType.Translation) {
      return TextPropertyInput
    }
    if (propertyDefinition.type === EProductPropertyType.Number) {
      return NumberPropertyInput
    }
    if (propertyDefinition.type === EProductPropertyType.Boolean) {
      return BooleanPropertyInput
    }
    return null
  })()

  const onPropertyValueCreate = useCallback(() => {
    const updatedAvailableProperties =
      cloneAvailableProperties(availableProperties)
    const value = updatedAvailableProperties.find(
      (p) => p.propertyDefinitionId === props.property.propertyDefinitionId,
    )
    if (
      !value ||
      value.propertyDefinitionType === EProductPropertyType.Boolean
    ) {
      throw new Error('Cannot create property value')
    }
    if (value.propertyDefinitionType === EProductPropertyType.Translation) {
      value.values.push({
        value: {
          en: null,
          de: null,
        },
        valueId: uniqueId(),
        status: 'draft',
      })
    } else if (value.propertyDefinitionType === EProductPropertyType.Number) {
      value.values.push({
        value: null,
        valueId: uniqueId(),
        status: 'draft',
      })
    }
    setFieldValue('availableProperties', updatedAvailableProperties)
  }, [availableProperties, props.property.propertyDefinitionId, setFieldValue])

  const onPropertyValueUpdate = useCallback(
    (options: OnChangePropertyInputOptions) => {
      const updatedAvailableProperties =
        cloneAvailableProperties(availableProperties)
      const value = updatedAvailableProperties.find(
        (p) => p.propertyDefinitionId === props.property.propertyDefinitionId,
      )
      if (
        !value ||
        value.propertyDefinitionType === EProductPropertyType.Boolean
      ) {
        return
      }
      const propertyDefinition = props.property.propertyDefinition
      const { valueId, newValue, languageCode } = options
      const option = value.values.find((v) => v.valueId === valueId)
      if (!option) {
        throw new Error('Cannot update property value')
      }
      if (value.propertyDefinitionType === EProductPropertyType.Translation) {
        if (option.value) {
          option.value[languageCode!] = newValue
        }
      } else if (value.propertyDefinitionType === EProductPropertyType.Number) {
        const numberValue = parseFloat(newValue!)
        if (!isNaN(numberValue)) {
          option.value = numberValue
        }
      }
      if (
        isDuplicatedAvailableProperty(
          option.value,
          updatedAvailableProperties,
          propertyDefinition,
        )
      ) {
        option.error = 'DUPLICATED'
      }
      setFieldValue('availableProperties', updatedAvailableProperties)

      for (const variant of variants) {
        for (const value of variant.propertiesValues) {
          if (value.propertyDefinitionType === EProductPropertyType.Boolean) {
            continue
          }
          if (value.valueId === valueId) {
            value.value = option.value
          }
        }
      }
      setFieldValue('variants', variants)
    },
    [
      availableProperties,
      props.property.propertyDefinition,
      props.property.propertyDefinitionId,
      setFieldValue,
      variants,
    ],
  )

  const onPropertyValueDelete = useCallback(
    (valueId: string) => {
      const updatedAvailableProperties =
        cloneAvailableProperties(availableProperties)
      const value = updatedAvailableProperties.find(
        (p) => p.propertyDefinitionId === props.property.propertyDefinitionId,
      )
      if (
        !value ||
        value.propertyDefinitionType === EProductPropertyType.Boolean
      ) {
        return
      }
      let filteredValues = value.values
      if (value.propertyDefinitionType === EProductPropertyType.Translation) {
        filteredValues = value.values.filter(
          (value) => value.valueId !== valueId,
        )
      } else if (value.propertyDefinitionType === EProductPropertyType.Number) {
        filteredValues = value.values.filter(
          (value) => value.valueId !== valueId,
        )
      } else {
        throw new Error('Invalid property type')
      }
      value.values = filteredValues
      setFieldValue('availableProperties', updatedAvailableProperties)
    },
    [availableProperties, props.property.propertyDefinitionId, setFieldValue],
  )

  return (
    <Box
      style={{ display: 'flex', justifyContent: 'flex-start', marginTop: 15 }}
    >
      <Typography style={{ minWidth: 130, fontWeight: 500 }}>
        {props.property.propertyDefinition?.key}
      </Typography>
      {Component && (
        <Component
          onPropertyValueDelete={onPropertyValueDelete}
          onPropertyValueCreate={onPropertyValueCreate}
          onPropertyValueUpdate={onPropertyValueUpdate}
          {...props}
        />
      )}
    </Box>
  )
}

type PropertyInputProps = Props & {
  onPropertyValueDelete: (valueId: string) => void
  onPropertyValueUpdate: (options: OnChangePropertyInputOptions) => void
  onPropertyValueCreate: () => void
}

export function TextPropertyInput(props: PropertyInputProps) {
  const {
    property,
    onPropertyValueDelete,
    onPropertyValueCreate,
    onPropertyValueUpdate,
  } = props
  const { t } = useTranslation()
  const [getErrorMessages] = useGetPropertyValueErrorMessages()
  return (
    <Box>
      <Box style={{ display: 'flex', justifyContent: 'flex-start' }}>
        <Typography style={{ width: 200, marginLeft: 5, fontWeight: 500 }}>
          English
        </Typography>
        <Typography style={{ width: 200, marginLeft: 5, fontWeight: 500 }}>
          German
        </Typography>
      </Box>
      {property.propertyDefinitionType === EProductPropertyType.Translation &&
        property.values.map((value) => {
          const error = getErrorMessages({
            ...value,
            propertyDefinition: property.propertyDefinition,
            propertyDefinitionType: EProductPropertyType.Translation,
          })
          return (
            <Box style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <Box style={{ width: 200 }}>
                <TextInput
                  value={value.value?.[ELanguageCode.En]}
                  inputWidth={'120px'}
                  errorMessagePosition="bottom"
                  onChange={(newValue) =>
                    onPropertyValueUpdate({
                      valueId: value.valueId,
                      newValue,
                      languageCode: ELanguageCode.En,
                    })
                  }
                  error={error}
                  autoFocus={true}
                />
              </Box>
              <Box style={{ width: 200 }}>
                <TextInput
                  value={value.value?.[ELanguageCode.De]}
                  inputWidth={'120px'}
                  errorMessagePosition="bottom"
                  onChange={(newValue) =>
                    onPropertyValueUpdate({
                      valueId: value.valueId,
                      newValue,
                      languageCode: ELanguageCode.De,
                    })
                  }
                  error={error}
                />
              </Box>
              {value.status === 'draft' && (
                <ReactSVG
                  className={`u-pl-15`}
                  src={DeleteIcon}
                  style={{ marginTop: 5, cursor: 'pointer' }}
                  onClick={() => {
                    onPropertyValueDelete(value.valueId)
                  }}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      onPropertyValueDelete(value.valueId)
                    }
                  }}
                  tabIndex={0}
                />
              )}
            </Box>
          )
        })}

      <Box
        onClick={onPropertyValueCreate}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            onPropertyValueCreate()
          }
        }}
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          color: '#6750A4',
          cursor: 'pointer',
          marginTop: 5,
        }}
        tabIndex={0}
      >
        <ReactSVG src={AddIcon} />
        <Typography style={{ fontSize: 12 }}>{t('add_value')}</Typography>
      </Box>
    </Box>
  )
}

export function NumberPropertyInput(props: PropertyInputProps) {
  const {
    property,
    onPropertyValueDelete,
    onPropertyValueCreate,
    onPropertyValueUpdate,
  } = props
  const { t } = useTranslation()

  const [getErrorMessages] = useGetPropertyValueErrorMessages()

  return (
    <Box style={{ width: 200 }}>
      {property.propertyDefinitionType === EProductPropertyType.Number &&
        property.values.map((value) => {
          const error = getErrorMessages({
            ...value,
            propertyDefinition: property.propertyDefinition,
            propertyDefinitionType: property.propertyDefinitionType,
          })
          return (
            <Box>
              <Box style={{ display: 'flex', justifyContent: 'flex-start' }}>
                <Box>
                  <TextInput
                    value={value.value}
                    type="number"
                    inputWidth={'120px'}
                    error={error}
                    errorMessagePosition="bottom"
                    onChange={(newValue) =>
                      onPropertyValueUpdate({
                        valueId: value.valueId,
                        newValue,
                      })
                    }
                    autoFocus={true}
                  />
                </Box>
                {value.status === 'draft' && (
                  <ReactSVG
                    className={`u-pl-15`}
                    src={DeleteIcon}
                    style={{ marginTop: 5, cursor: 'pointer' }}
                    onClick={() => {
                      onPropertyValueDelete(value.valueId)
                    }}
                    tabIndex={0}
                  />
                )}
              </Box>
            </Box>
          )
        })}
      <Box
        onClick={onPropertyValueCreate}
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          color: '#6750A4',
          cursor: 'pointer',
          marginTop: 5,
        }}
        tabIndex={0}
      >
        <ReactSVG src={AddIcon} />
        <Typography style={{ fontSize: 12 }}>{t('add_value')}</Typography>
      </Box>
    </Box>
  )
}

export function BooleanPropertyInput() {
  const { t } = useTranslation()
  return (
    <Typography style={{ marginLeft: 5 }}>
      {t('yes')}/{t('no')}
    </Typography>
  )
}

interface OnChangePropertyInputOptions {
  valueId: string
  languageCode?: ELanguageCode
  newValue?: string
}

export const isDuplicatedAvailableProperty = (
  newValue: number | boolean | ProductAvailablePropertyTextOptions | null,
  availableProperties: ProductAvailableProperty[],
  propertyDefinition: Pick<
    ProductPropertyDefinition,
    '_id' | 'type' | 'impactsLook'
  >,
) => {
  if (!newValue) {
    return false
  }

  const availableProperty = availableProperties.find(
    (p) => p.propertyDefinitionId === propertyDefinition._id,
  )

  if (
    availableProperty?.propertyDefinitionType === EProductPropertyType.Boolean
  ) {
    return false
  }

  if (
    availableProperty?.propertyDefinitionType === EProductPropertyType.Number
  ) {
    return (
      availableProperty.values.filter((v) => v.value === newValue).length > 1
    )
  }

  if (
    availableProperty?.propertyDefinitionType ===
    EProductPropertyType.Translation
  ) {
    return (
      availableProperty.values.filter(
        (v) =>
          v.value?.[ELanguageCode.En] === newValue[ELanguageCode.En] ||
          v.value?.[ELanguageCode.De] === newValue[ELanguageCode.De],
      ).length > 1
    )
  }

  return true
}

/**
 * Clone available properties
 * cloneDeep for entire array doesn't work
 * @param availableProperties
 * @returns
 */
export const cloneAvailableProperties = (
  availableProperties: ProductAvailableProperty[],
) => {
  return availableProperties.map((property) => {
    let values:
      | {
          error?: string | undefined
          value: ProductAvailablePropertyTextOptions | null
        }[]
      | {
          error?: string | undefined
          value: number | null
        }[]
      | undefined = []

    if (property.propertyDefinitionType === EProductPropertyType.Translation) {
      values = property.values.map((value) => cloneDeep(value))
    }

    if (property.propertyDefinitionType === EProductPropertyType.Number) {
      values = property.values.map((value) => cloneDeep(value))
    }

    if (property.propertyDefinitionType === EProductPropertyType.Boolean) {
      values = undefined
    }

    return {
      ...property,
      values,
    }
  }) as Array<ProductAvailableProperty>
}
